import React, { useState } from 'react'
import { domain } from './Domain'
import { motion } from 'framer-motion'
import "../css/Manage.css"
import CategoryComponent from './CategoryComponent'
import ProductForm from './ProductForm'
import SupplierComponent from './SupplierComponent'
import StockComponent from './StockComponent'
import DamageComponent from './DamageComponent'
import OrderComponent from './OrderComponent'
import AssignmentComponent from './AssignmentComponent'
import ReceiveComponent from './RecieveComponent'
import EmployeeAssignments from './EmployeeAssignments'
import StationComponent from './StationComponent'

const Manage = () => {
    //const buttons = ["Category", "Suppliers", "Products", "Stock", "Damages", "Orders", "Daily Sales",]
    const buttons = ["Category", "Suppliers", "Stations", "Products", "Stock", "Damages", "Assignment", "Employee Assignments"]
    const [handleView, sethandleView] = useState("Category")
    return (
        <div className='p-4'>
            <div className='d-flex gap-2 otherActions'>
                {buttons.map(button => 
                    <motion.button
                        key={button}
                        initial={{y:10}}
                        animate={{y:0}}
                        whileHover={{scale:1.01}}
                        whileTap={{scale:0.9}}
                        onClick={()=>sethandleView(button)}>
                            {button}
                    </motion.button>
                )}
            </div>
            <h3 className='mt-2'>Viewing {handleView}</h3>
            {handleView === "Category"  && <CategoryComponent />}
            {handleView === 'Products' && <ProductForm />}
            {handleView === 'Suppliers' && <SupplierComponent/>}
            {handleView === 'Stations' && <StationComponent/>}
            {handleView === 'Stock' && <StockComponent/>}
            {handleView === 'Damages' && <DamageComponent/>}
            {handleView === 'Orders' && <OrderComponent/>}
            {handleView === 'Assignment' && <AssignmentComponent/>}
            {/*handleView === 'Daily Sales' && <ReceiveComponent/>*/}
            {handleView === 'Employee Assignments' && <EmployeeAssignments/>}
        </div>
    )
}

export default Manage