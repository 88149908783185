import { useEffect, useState } from "react";
import { useLoginContext } from './LoginContext';

const useFetch = (url) => {
    const auth = useLoginContext()
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(false);

    //using the auth token if user is authenticated for protected routes in python api
    let headers = {}
    if(auth.userInfo.token !== null){
        headers.Authorization = `Token ${auth.userInfo.token}`
    }
    
    useEffect(()=>{
        setLoading(true);
        const abortCon = new AbortController();
        fetch(url, {
            signal: abortCon.signal,
            headers
        })//associate each fetch request with the abort controller
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            setData(data);
            setLoading(false);
            setError(null);
        })
        .catch(err =>{
            if(err.name === "AbortError"){
                console.log("fetch aborted");
            }else{
                setError(err.message);
                setLoading(false);
            }
        })
        //console.log("we are refrshing again");
        return () => {
            //console.log("clean up function execution running!")
            abortCon.abort();
        }
    },[url, refresh])
    return {data, loading, error, refresh, setRefresh, setData}
}

export default useFetch