import React, {useState, useEffect, useRef} from 'react'
import '../css/OTPForm.css';

import { useNavigate } from 'react-router-dom';
import { useLoginContext } from './LoginContext';
import { domain } from './Domain';
import { useLocation } from 'react-router-dom';


function OTPForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const { username = '' } = location.state || {};

    const {setIsLoggedIn, userInfo, setUserInfo} = useLoginContext();

    const [otp, setOtp] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [login, setLogin] = useState(false)

    const handleLogin = (data) => {
        if(data['token']){
            setLogin(false)
            setIsLoggedIn(true);
            userInfo.id = data['user_info'].id
            userInfo.name = data['user_info'].fullname
            userInfo.date = data['user_info'].date_joined
            userInfo.image = data['user_info'].image
            userInfo.role = data['user_info'].role
            userInfo.token = data['token']
            setUserInfo({ ...userInfo});
            //setErrorMessage('');
            navigate('/dashboard');
        }   
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLogin(true)
        const formData = new FormData();
        const requestOptions = {
            method: 'POST',
            credentials: "omit",
            body: formData
        };
        formData.append("username",username)
        formData.append("otp",otp)
        fetch(`${domain}/accounts/verify-otp`, requestOptions)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText)
            }
            return res.json()
        })
        .then(data =>{
            console.log(data)
            setErrorMessage('');
            handleLogin(data)
        })
        .catch(err =>{
            //console.log(err.message)
            setErrorMessage('Invalid OTP. Please try again.');
            setLogin(false)
        })
    };

    // Handle case where username is not available
    if (!username) {
        alert('No User Information Found')
        navigate('/');  // Redirect to an error page or handle as needed
        return null; // Return null to prevent rendering the rest of the component
    }

    return (
        <div className="otp">
            <h2 className='fs-5 fw-bold'>OTP Verification</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="otp">Enter OTP {username}:</label>
                    <input
                        type="text"
                        id="otp"
                        name="otp"
                        placeholder='Enter Received OTP'
                        maxLength="6"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">{login ? 'Verifying OTP ...' : 'Verify OTP'}</button>
            </form>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
    );
}

export default OTPForm;
