import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import { useLoadingContext } from './LoadingContext';
import useFetch from './useFetch';

const ExpensesComponent = () => {
    const { setLoading } = useLoadingContext()
    const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest()
    const { data, loading, refresh, setRefresh, error } = useFetch(`${domain}/expenses`)
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const initial = {
        title: '',
        description: '',
        amount: '',
        date: new Date().toISOString()
    }
    const [formData, setFormData] = useState(initial);
    const [selectedExpenseId, setSelectedExpenseId] = useState(null);

    useEffect(()=>{
        if(loading){
            setLoading(true)
        }else{
            setLoading(false)
        }
    }, [loading])

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            setLoading(true)
            await deleteRequest(`${domain}/expenses/${id}`);
            setRefresh(!refresh)
            setLoading(false)
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formDatas = new FormData();
        for (const key in formData) {
            if (formData[key] === "") {
                alert('fill all fields')
                return
            } else {
                formDatas.append(key, formData[key]);
            }
        }

        try {
            setLoading(true)
            if (selectedExpenseId) {
                await putRequest(`${domain}/expenses/${selectedExpenseId}`, formDatas);
                setSuccessMessage('Expense updated successfully.');
                setRefresh(!refresh)
            } else {
                await postRequest(`${domain}/expenses`, formDatas);
                setSuccessMessage('Expense added successfully.');
                setRefresh(!refresh)
            }
            setLoading(false)
        } catch (error) {
            if (selectedExpenseId) {
                console.error('Error updating Expense:', error);
                setErrorMessage('Error updating Expense.', error);
            } else {
                console.error('Error adding Expense:', error);
                setErrorMessage('Error adding Expense.', error);
            }
            setLoading(false)
        }

        setFormData(initial);
        setSelectedExpenseId(null);
        event.target.reset()
    };


    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleUpdate = (selectedItem) => {
        setFormData({
            title: selectedItem.title,
            description: selectedItem.description,
            amount: selectedItem.amount,
            date: selectedItem.date
        });
        setSelectedExpenseId(selectedItem.id);
        //inputRef.current.focus();
    };

    const handleCancelEdit = () => {
        setSelectedExpenseId(null);
        setFormData(initial);
    };


    return (
        <div className='p-4'>
            <form onSubmit={handleSubmit}>

                <h3>{selectedExpenseId ? 'Edit Expense' : 'Create New Expense'}</h3>
                <div className='d-flex gap-2 mb-2 align-items-end'>
                    <div className='w-25'>
                        <label>Expense Title:</label>
                        <input type="text" name="title" placeholder='Title' value={formData.title} onChange={handleChange} required />
                    </div>
                    <div className='w-25'>
                        <label>Expense Description:</label>
                        <input type="text" placeholder = "Description" name="description" value={formData.description} onChange={handleChange} required />
                    </div>
                    <div className='w-25'>
                        <label>Amount:</label>
                        <input type="number" placeholder='Amount used' name="amount" value={formData.amount} onChange={handleChange} required />
                    </div>
                    <div className='w-25'>
                        <label>Date:</label>
                        <input type="date" name="date" value={formData.date} onChange={handleChange} required />
                    </div>
                    <button className='w-25 btn btn-success' type="submit">{selectedExpenseId ? 'Update Expense' : 'Create Expense'}</button>
                    {selectedExpenseId && (
                        <button type='button' onClick={handleCancelEdit} className='btn btn-danger ms-2'>Cancel</button>
                    )}
                </div>
            </form>
                <h3>Available Expenses</h3>
                <table>
                    <tr>
                        <th>No</th>
                        <th>Name of item</th>
                        <th>Description of item</th>
                        <th>Date</th>
                        <th>Amount (Ugx)</th>
                        <th>Action</th>
                    </tr>
                    
                    {data && data.length === 0 &&
                        <tr>
                            <td>0</td>
                            <td>None</td>
                            <td>None</td>
                            <td>None</td>
                            <td>None</td>
                            <td>None</td>
                        </tr>
                    }
                    {data && data.map((item, index) => (
                        <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.title}</td>
                            <td>{item.description}</td>
                            <td>{item.date}</td>
                            <td>{item.amount && item.amount.toLocaleString()}</td>
                            <td>
                                <div className='d-flex gap-2'>
                                    <button className='btn btn-sm btn-success flex-grow-1' onClick={() => handleUpdate(item)}>Edit</button>
                                    <button className='btn btn-sm btn-danger flex-grow-1' onClick={() => handleDelete(item.id)}>Delete</button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </table>
        </div>
    )
}

export default ExpensesComponent