// NotFoundPage.jsx

import React from 'react';
import '../css/NotFoundPage.css'; // Import CSS for styling

const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
      <a href="/">Go to Home</a>
    </div>
  );
};

export default NotFoundPage;
