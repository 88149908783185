import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLoadingContext } from './LoadingContext';
import Loads from './Loads';

const StockComponent = () => {
    const { setLoading } = useLoadingContext()
    const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest()
    const [selectedProductId, setSelectedProductId] = useState(null);
    const inputRef = useRef();
    const [products, setProducts] = useState([]);
    const [stockList, setStockList] = useState([]);
    const [stationList, setStationList] = useState([]);
    const initial = {
        product: '',
        quantity: '',
        station: '',
        expiry: new Date().toISOString(),
        stock_alert: ''
    }
    const [formData, setFormData] = useState(initial);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    // Destructure values from formData
    const { product, quantity, station, expiry, stock_alert } = formData;

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
    
            try {
                // Perform all fetch requests in parallel and wait for all of them to complete
                await Promise.all([fetchProducts(), fetchStock(), fetchStations()]);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                // Set loading to false when all requests are completed (or error occurs)
                setLoading(false);
            }
        };
    
        fetchData();
    }, []);

    const fetchProducts = async () => {
        const response = await getRequest(`${domain}/products`);
        setProducts(response);
    };

    const fetchStations = async () => {
        const response = await getRequest(`${domain}/stations`);
        setStationList(response);
    };

    const fetchStock = async () => {
        try {
            const response = await getRequest(`${domain}/stocks`);
            setStockList(response);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleUpdate = (stockItem) => {
        setFormData({
            product: stockItem.product,
            quantity: stockItem.quantity,
            station: stockItem.station,
            stock_alert: stockItem.stock_alert,
            expiry: stockItem.expiry
        });
        setSelectedProductId(stockItem.id);
        inputRef.current.focus();
    };

    const handleCancelEdit = () => {
        setSelectedProductId(null);
        setFormData(initial);
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            setLoading(true)
            await deleteRequest(`${domain}/stocks/${id}`);
            fetchStock();
            setLoading(false)
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formDatas = new FormData();
        for (const key in formData) {
            formDatas.append(key, formData[key]);
        }

        try {
            setLoading(true)
            if (selectedProductId) {
                await putRequest(`${domain}/stocks/${selectedProductId}`, formDatas);
                setSuccessMessage('Stock updated successfully.');
            } else {
                await postRequest(`${domain}/stocks`, formDatas);
                setSuccessMessage('Stock added successfully.');
            }
            setLoading(false)
        } catch (error) {
            if (selectedProductId) {
                console.error('Error updating Stock:', error);
                setErrorMessage('Error updating Stock.');
            } else {
                console.error('Error adding stock:', error);
                setErrorMessage('Error adding stock.');
            }
            setLoading(false)
        }

        setFormData(initial);
        setSelectedProductId(null);
        fetchProducts();
        fetchStock();
        event.target.reset()
    };

    return (
        <div>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
            <form onSubmit={handleSubmit}>
                <div className='d-flex gap-2 mb-2'>
                    <div className='w-25'>
                        <label>Select Product:</label>
                        <select value={product} name='product' className='w-100' onChange={handleInputChange}>
                            <option value="">Select a product</option>
                            {products.length > 0 && products.map(product => (
                                <option key={product.id} value={product.id}>{product.product_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className='w-25'>
                        <label>Select Station:</label>
                        <select value={station} name='station' className='w-100' onChange={handleInputChange}>
                            <option value="">Select a station</option>
                            {stationList.length > 0 && stationList.map(station => (
                                <option key={station.id} value={station.id}>{station.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className='flex-grow-1'>
                        <label>Quantity:</label>
                        <input type="number" ref={inputRef} name="quantity" placeholder='product quantity' value={quantity} onChange={handleInputChange} />
                    </div>
                    <div className='flex-grow-1'>
                        <label>Expiry Date:</label>
                        <input type="date" ref={inputRef} name="expiry" value={expiry} onChange={handleInputChange} />
                    </div>
                    <div className='flex-grow-1'>
                        <label>Stock Alert:</label>
                        <input type="number" ref={inputRef} name="stock_alert" placeholder='stock alert value' value={stock_alert} onChange={handleInputChange} />
                    </div>
                </div>
                <button type="submit" className='btn btn-sm btn-success'>{selectedProductId ? 'Update' : 'Add Stock'}</button>
                {selectedProductId && (
                    <button type='button' onClick={handleCancelEdit} className='btn btn-sm btn-danger ms-2'>Cancel Update</button>
                )}
            </form>
            <h3 className='mt-3'>Existing Products</h3>
            <table>
                <tr>
                    <th style={{width:50}}>No</th>
                    <th>Product Name</th>
                    <th>Quantity in Stock</th>
                    <th>Station</th>
                    <th>Expiry Date</th>
                    <th style={{width:100}}>Action</th>
                </tr>
                {}
                {stockList && stockList.length > 0 && stockList.map((stockItem, index) => (
                    <tr key={stockItem.id}>
                        <td>{index + 1}</td>
                        <td>{products && products.find(item => item.id === stockItem.product)?.product_name}</td>
                        <td>{stockItem.quantity}</td>
                        <td>{stationList && stationList.find(item => item.id === stockItem.station)?.name}</td>
                        <td>{stockItem.expiry}</td>
                        <td>
                            <div className='d-flex gap-2'>
                                <button onClick={() => handleUpdate(stockItem)} className='btn btn-sm btn-danger'>Edit</button>
                                <button onClick={() => handleDelete(stockItem.id)} className='btn btn-sm btn-success'>Delete</button>
                            </div>
                        </td>
                    </tr>
                ))}
            </table>
        </div>
    );
};

export default StockComponent;
