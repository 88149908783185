import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import { useLoadingContext } from './LoadingContext';
import useFetch from './useFetch';

const DebtPayments = ({ payments }) => {
    const { setLoading } = useLoadingContext()
    const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest()
    const { data, loading, refresh, setRefresh, error } = useFetch(`${domain}/debts`)
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [filter, setFilter] = useState('')

    const initial = {
        debt: '',
        amount: '',
        registered_by: ''
    }
    const [formData, setFormData] = useState(initial);
    const [selectedDebtId, setSelectedDebtId] = useState(null);
    const [debtPay, setDebtPay] = useState(null);
    const [debtPayments, setDebtPayments] = useState([]);

    const handleFilter = (e) => {
        setFilter(e.target.value);
    };

    // Filtered debt payments based on user input
    const filteredPayments = debtPayments.filter(debt =>
        debt.debt.employee.owner.first_name.toLowerCase().includes(filter.toLowerCase()) ||
        debt.debt.employee.owner.last_name.toLowerCase().includes(filter.toLowerCase())
    );


    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this debt payment?")) {
            setLoading(true)
            await deleteRequest(`${domain}/debt-payments/${id}`);
            setRefresh(!refresh)
            fetchDebtPayments();
            setLoading(false)
        }
    };

    const fetchDebtPayments = async () => {
        const response = await getRequest(`${domain}/debt-payments`);
        console.log('Fetched debt payments:', response); // Log the response
        setDebtPayments(response);
    };


    useEffect(() => {
        const fetchUserInfo = () => {
            const storedUserInfo = localStorage.getItem('userInfo');
            if (storedUserInfo) {
                const userInfo = JSON.parse(storedUserInfo); // Parse the string into an object
                setFormData(prev => ({ ...prev, registered_by: userInfo.id })); // Use userInfo.id
            }
        };
    
        const fetchData = async () => {
            setLoading(true);
            await fetchDebtPayments();
            fetchUserInfo(); // Call the fetchUserInfo function
            setLoading(false);
        };

        fetchData()
    }, []);

    /*
    useEffect(() => {
        if (formData.debt !== "") {
            const amount = data.find(item => item.id === parseInt(formData.debt))?.amount
            setFormData(prev => ({ ...prev, amount, amount }))
        }
    }, [formData.debt])
    */

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formDatas = new FormData();
        for (const key in formData) {
            if (formData[key] === "") {
                alert('fill all fields')
                return
            } else {
                formDatas.append(key, formData[key]);
            }
        }

        try {
            setLoading(true)
            if (selectedDebtId) {
                await putRequest(`${domain}/debt-payments/${selectedDebtId}`, formDatas);
                setSuccessMessage('Payment Debt updated successfully.');
                fetchDebtPayments();
                setRefresh(!refresh)
                handleCancelEdit()
            } else {
                await postRequest(`${domain}/debt-payments`, formDatas);
                fetchDebtPayments();
                setRefresh(!refresh)
                setSuccessMessage('Payment Debt added successfully.');
            }
            setLoading(false)
        } catch (error) {
            if (selectedDebtId) {
                console.error('Error updating Debt:', error);
                setErrorMessage('Error updating Debt.', error);
            } else {
                console.error('Error adding debt:', error);
                setErrorMessage('Error adding debt.', error);
            }
            setLoading(false)
        }

        setFormData(initial);
        setSelectedDebtId(null);
        event.target.reset()

        const fetchUserInfo = () => {
            const storedUserInfo = localStorage.getItem('userInfo');
            if (storedUserInfo) {
                const userInfo = JSON.parse(storedUserInfo); // Parse the string into an object
                setFormData(prev => ({ ...prev, registered_by: userInfo.id })); // Use userInfo.id
            }
        };
        fetchUserInfo()
    };


    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleUpdate = (debtItem) => {
        setFormData({
            ...formData,
            debt: debtItem.id,
            amount: debtItem.amount,
        });
        setDebtPay(true)
        //inputRef.current.focus();
    };

    const handleUpdate2 = (debtItem) => {
        setFormData({
            ...formData,
            debt: debtItem.debt.id,
            amount: debtItem.amount,
        });
        setSelectedDebtId(debtItem.id);
        //inputRef.current.focus();
    };

    const handleCancelEdit = () => {
        setSelectedDebtId(null);
        setFormData(initial);
        setDebtPay(null)
    };

    useEffect(()=>{
        handleCancelEdit()
    },[payments])


    return (
        <div>
            <form onSubmit={handleSubmit}>
                <h3>{selectedDebtId ? 'Edit Debt Payment' : 'Create Debt Payment'}</h3>
                <div className='d-flex gap-2 mb-2 align-items-end'>
                    <p className='d-none'>{JSON.stringify(data)}</p>
                    <div className='w-25'>
                        <label>Select Debt:</label>
                        <select value={formData.debt} name='debt' className='w-100' onChange={handleChange}>
                            <option value="">Select a Debt Registered</option>
                            {data && data.map(debt => (
                                <option key={debt.id} value={debt.id}>{debt.employee.owner.first_name} {debt.employee.owner.last_name} - {debt.amount && debt.amount.toLocaleString()}</option>
                            ))}
                        </select>
                    </div>
                    <div className='w-25'>
                        <label>Amount:</label>
                        <input type="number" name="amount" value={formData.amount} onChange={handleChange} required placeholder='Enter Amount to pay' />
                    </div>
                    <button className='w-25 btn btn-success' type="submit">{selectedDebtId ? 'Update Debt Payment' : 'Create Debt Payment'}</button>
                    {selectedDebtId && (
                        <button type='button' onClick={handleCancelEdit} className='btn btn-danger ms-2'>Cancel</button>
                    )}
                    {debtPay && (
                        <button type='button' onClick={handleCancelEdit} className='btn btn-danger ms-2'>Cancel</button>
                    )}

                </div>
            </form>

            {payments ?
                <>
                    <div className='d-flex align-items-center gap-4'>
                        <h3 style={{width: 200}}>Debt Payments</h3>
                        <input type='search' placeholder='Enter Staff Name To Search / filter results for' onChange={(e)=>handleFilter(e)}/>
                    </div>
                    <table>
                        <tr>
                            <th style={{width: 50}}>No</th>
                            <th>Employee Name</th>
                            <th>Amount (Ugx)</th>
                            <th>Captured By</th>
                            <th>Payment Date</th>
                            <th style={{width: 50}}>Action</th>
                        </tr>
                        {/* {debtPayments && debtPayments.map((debt, index) => ( */}
                        {filteredPayments.map((debt, index) => (
                            <tr key={debt.id}>
                                <td>{index + 1}</td>
                                <td>{debt.debt.employee.owner.first_name} {debt.debt.employee.owner.last_name}</td>
                                <td>{debt.amount && debt.amount.toLocaleString()}</td>
                                <td>{debt.registered_by.first_name} {debt.registered_by.last_name}</td>
                                <td>{debt.created && new Date(debt.created).toLocaleString()}</td>
                                <td>
                                    <div className='d-flex gap-2'>
                                        <button className='btn btn-sm btn-success' onClick={() => handleUpdate2(debt)}>Edit</button>
                                        <button className='btn btn-sm btn-danger' onClick={() => handleDelete(debt.id)}>Delete</button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </table>
                </>
                :
                <>

                    <h3>Available Debtors</h3>
                    <table>
                        <tr>
                            <th style={{width: 50}}>No</th>
                            <th>Employee Name</th>
                            <th>Amount (Ugx)</th>
                            <th style={{width: 120}}>Action</th>
                        </tr>
                        {data && data.map((debt, index) => (
                            <tr key={debt.id}>
                                <td>{index + 1}</td>
                                <td>{debt.employee.owner.first_name} {debt.employee.owner.last_name}</td>
                                <td>{debt.amount && debt.amount.toLocaleString()}</td>
                                <td>
                                    <div className='d-flex gap-2'>
                                        <button className='btn btn-sm btn-success' onClick={() => handleUpdate(debt)}>Add Payment</button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </table>
                </>
            }
        </div>
    )
}

export default DebtPayments