import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import { useLoadingContext } from './LoadingContext';
import * as XLSX from 'xlsx';

const DamageComponent = () => {
    const { setLoading } = useLoadingContext()
    const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest()
    const [selectedDamageId, setSelectedDamageId] = useState(null);
    const inputRef = useRef();
    const [productList, setProductList] = useState([]);
    const [damageList, setDamageList] = useState([]);
    const [assignmentList, setAssignmentList] = useState([]);
    const [users, setUsers] = useState([]);
    const initial = {
        reported_by: "",
        assignment: '',
        quantity: '',
        description: '',
    }
    const [formData, setFormData] = useState(initial);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    // Destructure values from formData
    const { assignment, quantity, description, reported_by } = formData;

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
    
            try {
                // Perform all fetch requests in parallel and wait for all of them to complete
                await Promise.all([fetchDamages(), fetchUsers(), fetchAssignments(), fetchProducts()]);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                // Set loading to false when all requests are completed (or error occurs)
                setLoading(false);
            }
        };
        fetchData()
    }, []);

    useEffect(() => {
        selectedDamageId === null && setFormData({ ...formData, assignment: '', quantity: '', description: '' })
    }, [reported_by])

    const fetchDamages = async () => {
        try {
            const response = await getRequest(`${domain}/damages`);
            setDamageList(response);
        } catch (error) {
            console.error('Error fetching damages:', error);
        }
    };

    const fetchProducts = async () => {
        try {
            const response = await getRequest(`${domain}/products`);
            setProductList(response);
        } catch (error) {
            console.error('Error fetching damages:', error);
        }
    };


    const fetchUsers = async () => {
        const response = await getRequest(`${domain}/accounts/users`);
        setUsers(response);
    };

    const fetchAssignments = async () => {
        try {
            const response = await getRequest(`${domain}/assignments`);
            setAssignmentList(response);
        } catch (error) {
            console.error('Error fetching assignments:', error);
        }
    };


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleUpdate = (damageItem) => {
        setFormData({
            assignment: damageItem.assignment,
            quantity: damageItem.quantity,//assignmentList.find(item => item.id === parseInt(damageItem.assignment)).id,
            description: damageItem.description,
            reported_by: users.find(item => item.id === damageItem.reported_by)?.id
        });
        setSelectedDamageId(damageItem.id);
        inputRef.current.focus();
    };

    const handleCancelEdit = () => {
        setSelectedDamageId(null);
        setFormData(initial);
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            setLoading(true)
            await deleteRequest(`${domain}/damages/${id}`);
            fetchDamages();
            setLoading(false)
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formDatas = new FormData();
        for (const key in formData) {
            formDatas.append(key, formData[key]);
        }

        try {
            setLoading(true)
            if (selectedDamageId) {
                await putRequest(`${domain}/damages/${selectedDamageId}`, formDatas);
                setSuccessMessage('Stock updated successfully.');
            } else {
                await postRequest(`${domain}/damages`, formDatas);
                setSuccessMessage('Stock added successfully.');
            }
            setLoading(false)
        } catch (error) {
            if (selectedDamageId) {
                console.error('Error updating Damages:', error);
                setErrorMessage('Error updating Damage.');
            } else {
                console.error('Error adding damage:', error);
                setErrorMessage('Error adding damage.');
            }
            setLoading(false)
        }

        setFormData(initial);
        setSelectedDamageId(null);
        fetchAssignments();
        fetchDamages();
        event.target.reset()
    };

    const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate = ({ currentDate }) => {
        return new Intl.DateTimeFormat('en-US', options).format(currentDate);
    }

    const getUserName = (id) => {
        const found = users && users.length > 0 && users.find(item => item.id === id).owner
        return `${found.first_name} ${found.last_name}`
    }

    const exportToExcel = () => {
        //create a date aspect to appent to the download
        let newData = []
        for(let i = 0; i <= damageList.length - 1; i++){
            newData.push({
                product: `${assignmentList && assignmentList.length > 0 && productList && productList.length > 0 && productList.find(item => item.id === assignmentList.find(item => item.id === damageList[i].assignment)?.product).product_name}`,
                quantity: damageList[i].quantity,
                damaged_by: getUserName(damageList[i].reported_by),
                description:damageList[i].description
            })
        }
        const date = new Date();
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        }).replace(/\s+/g, '-'); // Replace spaces with hyphens
        // Create a new workbook
        const wb = XLSX.utils.book_new();
        // Convert JSON data to a worksheet
        const ws = XLSX.utils.json_to_sheet(newData);
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        // Generate a binary string representation of the workbook
        const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });
        // Convert the binary string to a Blob
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        // Create a download link
        const url = URL.createObjectURL(blob);
        // Trigger a click event on the link to download the file
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `damages-${formattedDate}.xlsx`);
        document.body.appendChild(link);
        link.click();
        // Clean up
        document.body.removeChild(link);
    };
    
    // Function to convert a string to an ArrayBuffer
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    };


    return (
        <div>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
            <form onSubmit={handleSubmit}>
                <div className='d-flex gap-2 mb-2'>
                    <div className='w-25'>
                        <label>Select User:</label>
                        <select value={reported_by} name='reported_by' className='w-100' onChange={handleInputChange}>
                            <option value="">Select a user</option>
                            {users.length > 0 && users.map(user => (
                                <option key={user.id} value={user.id}>{user.owner.first_name} {user.owner.last_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className='w-25'>
                        <label>Select Product:</label>
                        <select disabled={reported_by === "" ? true : false} value={assignment} name='assignment' className='w-100' onChange={handleInputChange}>
                            <option value="">Select a product</option>
                            {reported_by !== "" && assignmentList.length > 0 && assignmentList.filter(item => item.employee === parseInt(reported_by)).map(assignment => (
                                <option key={assignment.id} value={assignment.id}>{productList.find(item => item.id === assignment.product).product_name} {formattedDate(assignment.date_assigned)}</option>
                            ))}
                        </select>
                    </div>
                    <div className='flex-grow-1'>
                        <label>Quantity:</label>
                        <input disabled={assignment === "" ? true : false} type="number" ref={inputRef} name="quantity" placeholder='product quantity' value={quantity} onChange={handleInputChange} />
                    </div>
                </div>
                <label>Description of what happened</label>
                <textarea value={description} name='description' placeholder='Brief details of what happened ...' onChange={handleInputChange} />
                <button type="submit" className='btn btn-sm btn-success'>{selectedDamageId ? 'Update' : 'Add Damage'}</button>
                {selectedDamageId && (
                    <button type='button' onClick={handleCancelEdit} className='btn btn-sm btn-danger ms-2'>Cancel Update</button>
                )}
            </form>
            
            <div className='d-flex justify-content-between align-items-center'>
                <h3 className='mt-3'>Existing Products</h3>
                <button className='btn btn-sm btn-danger' onClick={exportToExcel}>Download Damage List</button>
            </div>
            <table>
                <tr>
                    <th style={{width:50}}>No</th>
                    <th>Product Name</th>
                    <th>Quantity</th>
                    <th>Damaged By</th>
                    <th>Description</th>
                    <th style={{width:100}}>Action</th>
                </tr>
                {damageList && damageList.length > 0 && damageList.map((damageItem, index) => (
                    <tr key={damageItem.id}>
                        <td>{index + 1}</td>
                        <td>{assignmentList && assignmentList.length > 0 && productList && productList.length > 0 && productList.find(item => item.id === assignmentList.find(item => item.id === damageItem.assignment)?.product).product_name}</td>
                        <td>{damageItem.quantity}</td>
                        <td>{getUserName(damageItem.reported_by)}</td>
                        <td>{damageItem.description}</td>
                        <td>
                            <div className='d-flex gap-2'>
                                <button onClick={() => handleUpdate(damageItem)} className='btn btn-sm btn-danger'>Edit</button>
                                <button onClick={() => handleDelete(damageItem.id)} className='btn btn-sm btn-success'>Delete</button>
                            </div>
                        </td>
                    </tr>
                ))}
            </table>
        </div>
    );
};

export default DamageComponent;
