import React, { useState, useRef } from 'react'
import "../css/Login.css"
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { useLoginContext } from './LoginContext';
import { domain } from './Domain';
import { motion, AnimatePresence } from 'framer-motion';
import { useActivePage } from './ActivePageContext';

const Login = () => {
    const { activePage, setActivePage } = useActivePage();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { isLoggedIn, setIsLoggedIn, userInfo, setUserInfo } = useLoginContext();
    const [login, setLogin] = useState(false)
    const [errors, setErrors] = useState("")
    const isLoginFormValid = username.trim() !== '' && password.trim() !== '';  

    const handleLogin = (e) => {
        e.preventDefault()
        setLogin(true)
        const formData = new FormData();
        const requestOptions = {
            method: 'POST',
            credentials: "omit",
            body: formData
        };
        formData.append("username",username)
        formData.append("password",password)
        fetch(`${domain}/accounts/login`, requestOptions)
        .then(res => {
            if(!res.ok){
                return res.json().then(data => {
                    setErrors(data['detail'])
                    console.log("Error response data:", data);
                    if (res.status === 401) {
                        throw new Error("Unauthorized");
                    }
                    throw new Error(`HTTP Error ${res.status}: ${data.detail}`);
                });
                //throw Error(res.statusText)
            }
            return res.json()
        })
        .then(data =>{
            console.log(data)
            if(data['token']){
                setLogin(false)
                setIsLoggedIn(true);
                setErrors("login successfull, redirecting ...")
                userInfo.id = data['user_info'].id
                userInfo.name = data['user_info'].fullname
                userInfo.date = data['user_info'].date_joined
                userInfo.image = data['user_info'].image
                userInfo.role = data['user_info'].role
                userInfo.token = data['token']
                setUserInfo({ ...userInfo});
                //setErrorMessage('');
                navigate('/dashboard');
            }   
            if (data['non_field_errors']) {
                setErrors(data['non_field_errors'][0])
                setLogin(false)
            }
            else{
                setErrors(data['detail'])
                setLogin(false)
            }
        })
        .catch(err =>{
            console.log("There was an error")
            console.log(err.message)
            //setErrors("Invalid credentials.")
            setLogin(false)
        })
    };
    return (
        <>
            <div id='loginForm' className='animate__animated animate__fadeIn'>
                <form onSubmit={handleLogin}>
                    <div className='text-center'><img src='/images/logo.png' alt='mukLogo' className='w-25'/></div>
                    <h2 class="fw-bold fs-5 pt-2 pb-2 text-center">Sign Into Your Account</h2>
                    {errors !== "" &&
                        <AnimatePresence>
                            <motion.p key={errors} initial={{scale: 0, opacity: 0}} animate={{scale: 1, opacity: 1}} exit={{scale: 0, opacity: 0}} className='alert alert-info'>{errors}</motion.p>
                        </AnimatePresence>
                    }
                    <div class="border rounded mb-2 p-3">
                        <label class="pb-1">Account username</label>
                        <input type="text" name="username" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="username" />
                        <label class="pb-1">Account password</label>
                        <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="password" />
                        {login ?
                            <p class="mt-2 m-0"><input disabled className='btn text-white bg-secondary' style={{ borderBottom: "5px solid var(--background)" }} type="button" value="logining you in" /></p>
                            :
                            <p class="mt-2 m-0"><input type="submit" className={isLoginFormValid === false && "btn text-white bg-secondary"} style={isLoginFormValid === false ? { borderBottom: "5px solid var(--background)"}:{}} disabled={!isLoginFormValid} value="login" /></p>
                        }
                        <Link to={'/forgot-password'} className='d-flex justify-content-end text-danger'>Forgot Password ?</Link>
                    </div>
                    <div class="d-flex justify-content-center gap-4 border rounded p-2">
                        <p class="pe-2 m-0"><small class="fs-6">New to Ashlink Platform?</small> </p>
                        <Link class="text-primary text-decoration-none" to={'/register'}><small class="fs-6">Create an Account</small></Link>
                    </div>
                    
                    <div class="text-center links">
                        <hr />
                        <a href="" class="text-decoration-none me-2">Conditions of Use</a>
                        <a href="" class="text-decoration-none me-2">Privacy Notice</a>
                        <p>Copyright &copy; {new Date().getFullYear()} Ashlink Ltd</p>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Login