import { useEffect, useState } from 'react';
/*
import { Outlet, Navigate } from 'react-router-dom';

const PrivateRoutes = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const storedUserInfo = localStorage.getItem('userInfo');
                if (storedUserInfo) {
                    const token = JSON.parse(storedUserInfo).token;
                    setIsAuthenticated(!!token);
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                console.error('Error fetching token:', error);
                setIsAuthenticated(false);
            } finally {
                setIsLoading(false); // Update loading state regardless of success or failure
            }
        };

        fetchToken();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>; // Render loading indicator
    }

    return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
*/

import { Outlet, Navigate, useNavigate } from 'react-router-dom'

const PrivateRoutes = () => {
    const navigate = useNavigate();
    //const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    let auth = {'token': JSON.parse(localStorage.getItem('userInfo'))['token'] ? true : false}

    return(
        auth.token ? <Outlet/> : <Navigate to={navigate(-1)} replace />//navigate(-1) takes a user back to the previous page they had access to
    )
}

export default PrivateRoutes


/*import { useEffect, useState } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';

const PrivateRoutes = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    console.log("we are in private routes")

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const storedUserInfo = localStorage.getItem('userInfo');
                console.log("this is the user info")
                if (storedUserInfo) {
                    const token = JSON.parse(storedUserInfo).token;
                    setIsAuthenticated(!!token);
                    //setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                console.error('Error fetching token:', error);
                setIsAuthenticated(false);
            } finally {
                setIsLoading(false); // Update loading state regardless of success or failure
            }
        };

        fetchToken();
    }, []);

    if(isLoading) {
        return <div>Loading...</div>; // Render loading indicator
    }

    return isAuthenticated ? <Outlet /> : <Navigate to={navigate(-1)} replace />;
};

export default PrivateRoutes;








/*import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoutes = () => {
    let auth = {'token': JSON.parse(localStorage.getItem('userInfo'))['token'] ? true : false}
    return(
        auth.token ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default PrivateRoutes
*/