import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLoadingContext } from './LoadingContext';

const ProductForm = () => {
    const { setLoading } = useLoadingContext()
    const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest()
    const [selectedProductId, setSelectedProductId] = useState(null);
    const inputRef = useRef();
    const [categories, setCategories] = useState([]);
    const [productList, setProductList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const initial = {
        category: '',
        supplier: '',
        product_name: '',
        description: '',
        cost_price: '',
        selling_price: ''
    }
    const [formData, setFormData] = useState(initial);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const modules = {
        toolbar: [
            [{header: [1,2,3,4,5,6, false]}],
            [{font: []}],
            [{size: []}],
            [{color: []}],
            [{background: []}],
            ['bold','italic','underline','strike','blockquote'],
            [
                {"list": "ordered"},
                {"list": "bullet"},
                {"indent": "-1"},
                {"indent": "+1"}
            ],
            ["link","image","video"],
            ["clean"],
        ]
    }

    // Destructure values from formData
    const { category, supplier, product_name, description, cost_price, selling_price } = formData;

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
    
            try {
                // Perform all fetch requests in parallel and wait for all of them to complete
                await Promise.all([fetchCategories(), fetchProducts(), fetchSuppliers()]);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                // Set loading to false when all requests are completed (or error occurs)
                setLoading(false);
            }
        };
    
        fetchData();
    }, []);

    const fetchProducts = async () => {
        const response = await getRequest(`${domain}/products`);
        setProductList(response);
    };

    const fetchSuppliers = async () => {
        const response = await getRequest(`${domain}/suppliers`);
        setSupplierList(response);
    };

    const fetchCategories = async () => {
        try {
            const response = await getRequest(`${domain}/categories`);
            setCategories(response);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleUpdate = (productItem) => {
        setFormData({
          category: productItem.category,
          supplier: productItem.supplier,
          product_name: productItem.product_name,
          description: productItem.description,
          cost_price: productItem.cost_price,
          selling_price: productItem.selling_price
        });
        setSelectedProductId(productItem.id);
        inputRef.current.focus();
    };
    
    const handleCancelEdit = () => {
        setSelectedProductId(null);
        setFormData(initial);
    };
    
    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
          await deleteRequest(`${domain}/products/${id}`);
          fetchProducts();
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formDatas = new FormData();
        for (const key in formData) {
            formDatas.append(key, formData[key]);
        }

        try {
            setLoading(true)
            if (selectedProductId) {
                await putRequest(`${domain}/products/${selectedProductId}`, formDatas);
                setSuccessMessage('Product updated successfully.');
            } else {
                await postRequest(`${domain}/products`, formDatas);
                setSuccessMessage('Product added successfully.');
            }
            setLoading(false)
        } catch (error) {
            if (selectedProductId) {
                console.error('Error updating product:', error);
                setErrorMessage('Error updating product.');
            } else {
                console.error('Error adding product:', error);
                setErrorMessage('Error adding product.');
            }
            setLoading(false)
        }

        setFormData(initial);
        setSelectedProductId(null);
        fetchProducts();
        event.target.reset()
    };

    return (
        <div>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
            <form onSubmit={handleSubmit}>
                <div className='d-flex gap-2'>
                    <div className='w-25'>
                        <label>Category:</label>
                        <select value={category} name='category' className='w-100' onChange={handleInputChange}>
                            <option value="">Select a category</option>
                            {categories.length > 0 && categories.map(category => (
                                <option key={category.id} value={category.id}>{category.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className='w-25'>
                        <label>Supplier:</label>
                        <select value={supplier} name='supplier' className='w-100' onChange={handleInputChange}>
                            <option value="">Select a supplier</option>
                            {supplierList.length > 0 && supplierList.map(supplier => (
                                <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className='flex-grow-1'>
                        <label>Product Name:</label>
                        <input type="text" ref={inputRef} name="product_name" placeholder='product name' value={product_name} onChange={handleInputChange} />
                    </div>
                </div>
                <div>
                    <label>Description:</label>
                    <ReactQuill 
                        theme="snow" 
                        value={description} // Set the value from notificationData
                        onChange={(content) => {
                            if(content !=="<p><br></p>"){
                                setFormData((prevData) => ({
                                    ...prevData,
                                    description: content // Update the 'value' property in notificationData
                                }));
                            }else{
                                setFormData((prevData) => ({
                                    ...prevData,
                                    description: "" // Update the 'value' property in notificationData
                                }));
                            }
                        }}
                        className='editor-input bg-white'
                        placeholder="product description here ....." // Add the placeholder option
                        //style={editorStyles}
                        modules = {modules} />
                </div>
                <div className='d-flex gap-2 mb-2'>
                    <div className='flex-grow-1'>
                        <label>Cost Price:</label>
                        <input type="number" placeholder='cost price' name="cost_price" value={cost_price} onChange={handleInputChange} />
                    </div>
                    <div className='flex-grow-1'>
                        <label>Selling Price:</label>
                        <input type="number" placeholder='selling price' name="selling_price" value={selling_price} onChange={handleInputChange} />
                    </div>
                </div>
                <button type="submit" className='btn btn-sm btn-success'>{selectedProductId ? 'Update' : 'Add Product'}</button>
                {selectedProductId && (
                <button type='button' onClick={handleCancelEdit} className='btn btn-sm btn-danger ms-2'>Cancel Update</button>
                )}
            </form>
            <h3 className='mt-3'>Existing Products</h3>
            <table>
                <tr>
                    <th style={{width:50}}>No</th>
                    <th>Product Name</th>
                    <th>Product Category</th>
                    <th>Description</th>
                    <th style={{width:100}}>Action</th>
                </tr>
                {productList && productList.length > 0 && productList.map((productItem, index) => (
                    <tr key={productItem.id}>
                        <td>{index + 1}</td>
                        <td>{productItem.product_name}</td>
                        <td>{categories && categories.find(item => item.id === productItem.category)?.name}</td>
                        <td className='w-50' dangerouslySetInnerHTML={{__html: productItem.description}}></td>
                        <td>
                            <div className='d-flex gap-2'>
                                <button onClick={() => handleUpdate(productItem)} className='btn btn-sm btn-danger'>Edit</button>
                                <button onClick={() => handleDelete(productItem.id)} className='btn btn-sm btn-success'>Delete</button>
                            </div>
                        </td>
                    </tr>
                ))}
            </table>
        </div>
    );
};

export default ProductForm;
