import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import { useLoadingContext } from './LoadingContext';
import useFetch from './useFetch';
import * as XLSX from 'xlsx';

const DebtComponent = () => {
    const { setLoading } = useLoadingContext()
    const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest()
    const { data, loading, refresh, setRefresh, error } = useFetch(`${domain}/debts`)
    const [users, setUsers] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const initial = {
        employee: '',
        amount: '',
    }
    const [formData, setFormData] = useState(initial);
    const [selectedDebtId, setSelectedDebtId] = useState(null);
    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this debt?")) {
            setLoading(true)
            await deleteRequest(`${domain}/debts/${id}`);
            setRefresh(!refresh)
            setLoading(false)
        }
    };

    const fetchUsers = async () => {
        const response = await getRequest(`${domain}/accounts/users`);
        setUsers(response);
    };

    const exportToExcel = () => {
        //create a date aspect to appent to the download
        let newData = []
        for(let i = 0; i <= data.length - 1; i++){
            newData.push({
                name: `${data[i].employee.owner.first_name} ${data[i].employee.owner.last_name}`,
                amount: data[i].amount
            })
        }
        const date = new Date();
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        }).replace(/\s+/g, '-'); // Replace spaces with hyphens
        // Create a new workbook
        const wb = XLSX.utils.book_new();
        // Convert JSON data to a worksheet
        const ws = XLSX.utils.json_to_sheet(newData);
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        // Generate a binary string representation of the workbook
        const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });
        // Convert the binary string to a Blob
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        // Create a download link
        const url = URL.createObjectURL(blob);
        // Trigger a click event on the link to download the file
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `debtors-${formattedDate}.xlsx`);
        document.body.appendChild(link);
        link.click();
        // Clean up
        document.body.removeChild(link);
    };
    
    // Function to convert a string to an ArrayBuffer
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch categories
                await fetchUsers();
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                // Set loading to false when the request is completed (or error occurs)
                setLoading(false);
            }
        };
    
        fetchData();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formDatas = new FormData();
        for (const key in formData) {
            if (formData[key] === "") {
                alert('fill all fields')
                return
            } else {
                formDatas.append(key, formData[key]);
            }
        }

        try {
            setLoading(true)
            if (selectedDebtId) {
                await putRequest(`${domain}/debts/${selectedDebtId}`, formDatas);
                setSuccessMessage('Assignment updated successfully.');
                setRefresh(!refresh)
            } else {
                await postRequest(`${domain}/debts`, formDatas);
                setSuccessMessage('Assignment added successfully.');
            }
            setLoading(false)
        } catch (error) {
            if (selectedDebtId) {
                console.error('Error updating Debt:', error);
                setErrorMessage('Error updating Debt.', error);
            } else {
                console.error('Error adding debt:', error);
                setErrorMessage('Error adding debt.', error);
            }
            setLoading(false)
        }

        setFormData(initial);
        setSelectedDebtId(null);
        fetchUsers();
        event.target.reset()
        setRefresh(!refresh)
    };


    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleUpdate = (debtItem) => {
        setFormData({
            employee: users.find(item => item.id === parseInt(debtItem.employee.owner.id))?.id,
            amount: debtItem.amount,
        });
        setSelectedDebtId(debtItem.id);
        //inputRef.current.focus();
    };

    const handleCancelEdit = () => {
        setSelectedDebtId(null);
        setFormData(initial);
    };


    return (
        <div>
            <form onSubmit={handleSubmit}>

                <h3>{selectedDebtId ? 'Edit Debt' : 'Create Debt'}</h3>
                <div className='d-flex gap-2 mb-2 align-items-end'>
                    <div className='w-25'>
                        <label>Select User:</label>
                        <select value={formData.employee} name='employee' className='w-100' onChange={handleChange}>
                            <option value="" disabled>Select a user</option>
                            {users.length > 0 && users.map(user => (
                                <option key={user.id} value={user.id}>{user.owner.first_name} {user.owner.last_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className='w-25'>
                        <label>Amount:</label>
                        <input type="number" name="amount" value={formData.amount} onChange={handleChange} placeholder='Enter debt amount' required />
                    </div>
                    <button className='w-25 btn btn-success' type="submit">{selectedDebtId ? 'Update Debt' : 'Create Debt'}</button>
                    {selectedDebtId && (
                        <button type='button' onClick={handleCancelEdit} className='btn btn-danger ms-2'>Cancel</button>
                    )}
                </div>
            </form>
            <div className='d-flex justify-content-between align-items-center'>
                <h3>Available Debtors</h3>
                <button className='btn btn-sm btn-danger' onClick={exportToExcel}>Download Debt List</button>
            </div>
            <table>
                <tr>
                    <th style={{width:50}}>No</th>
                    <th>Employee Name</th>
                    <th>Amount (Ugx)</th>
                    <th style={{width:100}}>Action</th>
                </tr>
                {data && data.length === 0 &&
                        <tr>
                            <td>0</td>
                            <td>None</td>
                            <td>None</td>
                            <td>None</td>
                            <td>None</td>
                        </tr>
                    }
                {data && data.map((debt, index) => (
                    <tr key={debt.id}>
                        <td>{index + 1}</td>
                        <td>{debt.employee.owner.first_name} {debt.employee.owner.last_name}</td>
                        <td>{debt.amount && debt.amount.toLocaleString()}</td>
                        <td>
                            <div className='d-flex gap-2'>
                                <button className='btn btn-sm btn-success' onClick={() => handleUpdate(debt)}>Edit</button>
                                <button className='btn btn-sm btn-danger' onClick={() => handleDelete(debt.id)}>Delete</button>
                            </div>
                        </td>
                    </tr>
                ))}
            </table>
        </div>
    )
}

export default DebtComponent