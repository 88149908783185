import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { json } from 'react-router-dom';

const OrderComponent = () => {
    const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest()
    const [selectedProductId, setSelectedProductId] = useState(null)
    const [selectedOrderItemId, setSelectedOrderItemId] = useState(null)
    const inputRef = useRef()
    const [productList, setProductList] = useState([])
    const [viewingOrder, setViewingOrder] = useState(false)
    const [orders, setOrders] = useState([]);
    const [orderItemList, setOrderItemList] = useState([])
    const [filtered, setFiltered] = useState([])
    const initial = {
      customer_name: '',
      customer_contact: ''
    }
    const [formData, setFormData] = useState(initial);

    const initOrderItem = {
      quantity: '',
      price: '',
      order: '',
      product: ''
    }
    const [newOrderItem, setNewOrderItem] = useState(initOrderItem);

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    // Destructure values from formData
    const { customer_name, customer_contact } = formData;
    const { quantity, price, order, product } = newOrderItem;

    useEffect(() => {
        fetchOrders()
        fetchOrderItems()
        fetchProducts()
    }, []);

    const fetchProducts = async () => {
      const response = await getRequest(`${domain}/products`);
      setProductList(response);
    };

    const fetchOrders = async () => {
        const response = await getRequest(`${domain}/orders`);
        setOrders(response);
    };

    const fetchOrderItems = async () => {
        try {
            const response = await getRequest(`${domain}/order-items`);
            setOrderItemList(response);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleUpdate = (orderItem) => {
        setFormData({
          customer_name: orderItem.customer_name,
          customer_contact: orderItem.customer_contact
        });
        setSelectedProductId(orderItem.id);
        inputRef.current.focus();
    };
    
    const handleCancelEdit = () => {
        setSelectedProductId(null);
        setFormData(initial);
    };
    
    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
          await deleteRequest(`${domain}/orders/${id}`);
          fetchOrders();
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formDatas = new FormData();
        for (const key in formData) {
            formDatas.append(key, formData[key]);
        }

        try {
            if (selectedProductId) {
                await putRequest(`${domain}/orders/${selectedProductId}`, formDatas);
                setSuccessMessage('Order updated successfully.');
            } else {
                await postRequest(`${domain}/orders`, formDatas);
                setSuccessMessage('Order added successfully.');
            }
        } catch (error) {
            if (selectedProductId) {
                console.error('Error updating Order:', error);
                setErrorMessage('Error updating Order.');
            } else {
                console.error('Error adding Order:', error);
                setErrorMessage('Error adding Order.');
            }
        }

        setFormData(initial);
        setSelectedProductId(null);
        fetchOrders();
        fetchOrderItems();
        event.target.reset()
    };

    //order items here 

    const handleViewOrders = async(item) => {
      const filtered = orderItemList.filter(order => order.order === item.id)
      setViewingOrder(true)
      setFiltered(filtered)
      //set the order id
      setNewOrderItem({...newOrderItem, order:item.id})
    }

    useEffect(()=>{
      if(newOrderItem.product !=='' && newOrderItem.quantity !== ''){
        const product_selling_price = productList.find(item => item.id === parseInt(newOrderItem.product))?.selling_price;
        setNewOrderItem(prevState => ({...prevState, price:product_selling_price * parseInt(newOrderItem.quantity)}))
      }else{
        setNewOrderItem(prevState => ({...prevState, price:''}))
      }
    },[newOrderItem.product, newOrderItem.quantity])


    const handleOrderItemChange = (e) => {
      const { name, value } = e.target;
      setNewOrderItem(prevState => ({
        ...prevState,
        [name]: value
      }));
    };

    const addOrderItem = async (e) => {
      e.preventDefault()
      const formDatas = new FormData();
      for (const key in newOrderItem) {
          formDatas.append(key, newOrderItem[key]);
      }

      try {
          if (selectedOrderItemId) {
              await putRequest(`${domain}/order-items/${selectedOrderItemId}`, formDatas);
              setSuccessMessage('Order Item updated successfully.');
          } else {
              await postRequest(`${domain}/order-items`, formDatas);
              setSuccessMessage('Order Item added successfully.');
          }
        } catch (error) {
          if (selectedOrderItemId) {
              console.error('Error updating Order Item:', error);
              setErrorMessage('Error updating Order Item.');
          } else {
              console.error('Error adding Order Item:', error);
              setErrorMessage('Error adding Order Item.');
          }
        }

      setNewOrderItem({
        ...newOrderItem,
        quantity: '',
        price: '',
        product: '' 
      });
      setSelectedOrderItemId(null);
      fetchOrders();
      fetchOrderItems();
      if(order !== ''){
        const filtered = orderItemList.filter(order => order.order === order)
        setFiltered(filtered)
      }
      e.target.reset()
    };

    const handleCancelOrderItemEdit = () => {
      setSelectedOrderItemId(null);
      setNewOrderItem(initOrderItem);
    }

    const deleteOrderItem = async(id) =>{
      if (window.confirm("Are you sure you want to delete this item?")) {
        await deleteRequest(`${domain}/order-items/${id}`);
        fetchOrderItems();
        if(order !== ''){
          const filtered = orderItemList.filter(order => order.order === order)
          setFiltered(filtered)
        }
      }
    }

    const updateOrderItem = (item) => {
      setNewOrderItem({
        quantity: item.quantity,
        price: item.price,
        order: item.order,
        product: item.product
      });
      setSelectedOrderItemId(item.id);
    }

    function getProductInfo(productId) {
      const product = productList.find(item => item.id === productId);
      return product ? `${product.product_name}` : '';
    }

    return (
        viewingOrder === false ?
          <div>
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
              <form onSubmit={handleSubmit}>
                  <div className='d-flex gap-2 mb-2'>
                      <div className='w-25'>
                          <label>Customer Name:</label>
                          <input type="text" ref={inputRef} name="customer_name" placeholder='customer name' value={customer_name} onChange={handleInputChange} />
                      </div>
                      <div className='flex-grow-1'>
                          <label>Customer Contact:</label>
                          <textarea name="customer_contact" rows={4} placeholder='customer contact' value={customer_contact} onChange={handleInputChange}></textarea>
                      </div>
                  </div>
                  <button type="submit" className='btn btn-sm btn-success'>{selectedProductId ? 'Update' : 'Add Order Information'}</button>
                  {selectedProductId && (
                  <button type='button' onClick={handleCancelEdit} className='btn btn-sm btn-danger ms-2'>Cancel Update</button>
                  )}
              </form>
              <h3 className='mt-3'>Existing Orders</h3>
              <ul className='p-0 m-0' style={{borderRadius: "5px"}}>
                  {orders && orders.length > 0 && orders.map(orderItem => (
                      <li key={orderItem.id} className='d-flex bg-white justify-content-between align-items-center mb-2 p-2 mb-2 rounded' style={{borderBottom: "2px solid var(--background)"}}>
                          <div className='d-flex gap-4 align-items-center'>
                              <div>
                                  <h2 className='fs-6 fw-bold'>{orderItem.customer_name}</h2>
                                  <p>{orderItem.customer_contact}</p>
                              </div>
                              <em className='text-muted'>created on {new Date(orderItem.created_at).toLocaleString()}</em>
                          </div>
                          <div className='d-flex gap-2'>
                              <button onClick={() => handleUpdate(orderItem)} className='btn btn-sm btn-success'>Edit</button>
                              <button onClick={() => handleDelete(orderItem.id)} className='btn btn-sm btn-danger'>Delete</button>
                              <button onClick={() => handleViewOrders(orderItem)} className='btn btn-sm btn-primary'>View Orders</button>
                          </div>
                      </li>
                  ))}
              </ul>
          </div>
        :
        <div>
          <div className='d-flex gap-2 align-items-center justify-content-between'>
            <h4 className='fs-6 text-danger'>Add New Order Item</h4>
            <button className='btn btn-sm btn-success' onClick={()=>{setViewingOrder(false); setNewOrderItem(initOrderItem)}}>back to orders</button>
          </div>
          <form onSubmit={addOrderItem}>
            <input type="hidden" name="order" value={order.id} />
            <div className='d-flex gap-2 mb-2'>
              <div className='w-25'>
                <label>Select Product:</label>
                <select value={product} name='product' className='w-100' onChange={handleOrderItemChange}>
                    <option value="">Select a product</option>
                    {productList.length > 0 && productList.map(product => (
                        <option key={product.id} value={product.id}>{product.product_name}</option>
                    ))}
                </select>
              </div>
              <div className='flex-grow-1'>
                <label>Quantity</label>
                <input type="text" name="quantity" placeholder="Quantity" value={quantity} onChange={handleOrderItemChange} />
              </div>
              <div className='flex-grow-1'>
                <label>Price</label>
                <input type="text" readOnly name="price" placeholder="Price" value={price} onChange={handleOrderItemChange} />
              </div>
            </div>
            <button type="submit" className='btn btn-sm btn-success'>{selectedOrderItemId ? 'Update' : 'Add Order Item'}</button>
            {selectedOrderItemId && (
              <button type='button' onClick={handleCancelOrderItemEdit} className='btn btn-sm btn-danger ms-2'>Cancel Update</button>
            )}
          </form>
          <ul className='p-0 m-0 mt-3' style={{borderRadius: "5px"}}>
            {filtered.length > 0 ? filtered.map(item => (
              <li key={item.id} className='d-flex bg-white justify-content-between align-items-center mb-2 p-2 mb-2 rounded' style={{borderBottom: "2px solid var(--background)"}}>
                <div className='d-flex gap-4 align-items-center'>
                  <p><span>{item.quantity}</span> x <span>{getProductInfo(item.product)}</span></p>
                  <p>Price: {item.price}</p>
                </div>
                <div className='d-flex gap-2'>
                  <button className='btn btn-sm btn-success' onClick={() => updateOrderItem(item)}>Update</button>
                  <button className='btn btn-sm btn-danger' onClick={() => deleteOrderItem(item.id)}>Delete</button>
                </div>
              </li>
              ))
            :
            orderItemList.map(item => (
              <li key={item.id} className='d-flex bg-white justify-content-between align-items-center mb-2 p-2 mb-2 rounded' style={{borderBottom: "2px solid var(--background)"}}>
                <div className='d-flex gap-4 align-items-center'>
                  <p><span>{item.quantity}</span> x <span>{getProductInfo(item.product)}</span></p>
                  <p>Price: {item.price}</p>
                </div>
                <div className='d-flex gap-2'>
                  <button className='btn btn-sm btn-success' onClick={() => updateOrderItem(item)}>Update</button>
                  <button className='btn btn-sm btn-danger' onClick={() => deleteOrderItem(item.id)}>Delete</button>
                </div>
              </li>
              ))}
          </ul>
        </div>
      
    );
};

export default OrderComponent;


/*
// OrderComponent.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const OrderComponent = () => {
  const [orders, setOrders] = useState([]);
  const [newOrder, setNewOrder] = useState({
    customer_name: '',
    customer_contact: ''
  });
  const [orderItems, setOrderItems] = useState([]);
  const [newOrderItem, setNewOrderItem] = useState({
    product_id: '',
    quantity: '',
    price: ''
  });

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await axios.get('/api/orders/');  // Replace with your API endpoint
      setOrders(response.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const addOrder = async () => {
    try {
      const response = await axios.post('/api/orders/', newOrder);  // Replace with your API endpoint
      setOrders([...orders, response.data]);
      setNewOrder({ customer_name: '', customer_contact: '' });
    } catch (error) {
      console.error('Error adding order:', error);
    }
  };

  const deleteOrder = async (orderId) => {
    try {
      await axios.delete(`/api/orders/${orderId}/`);  // Replace with your API endpoint
      setOrders(orders.filter(order => order.id !== orderId));
    } catch (error) {
      console.error('Error deleting order:', error);
    }
  };

  const updateOrder = async (orderId, updatedOrderData) => {
    try {
      const response = await axios.put(`/api/orders/${orderId}/`, updatedOrderData);  // Replace with your API endpoint
      const updatedOrders = orders.map(order => (order.id === orderId ? response.data : order));
      setOrders(updatedOrders);
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };

  const fetchOrderItems = async (orderId) => {
    try {
      const response = await axios.get(`/api/order-items/?order=${orderId}`);  // Replace with your API endpoint
      setOrderItems(response.data);
    } catch (error) {
      console.error(`Error fetching order items for order ${orderId}:`, error);
    }
  };

  const addOrderItem = async () => {
    try {
      const response = await axios.post('/api/order-items/', {
        order: newOrderItem.order_id,
        product: newOrderItem.product_id,
        quantity: newOrderItem.quantity,
        price: newOrderItem.price
      });  // Replace with your API endpoint
      setOrderItems([...orderItems, response.data]);
      setNewOrderItem({ product_id: '', quantity: '', price: '' });
    } catch (error) {
      console.error('Error adding order item:', error);
    }
  };

  const deleteOrderItem = async (orderItemId) => {
    try {
      await axios.delete(`/api/order-items/${orderItemId}/`);  // Replace with your API endpoint
      setOrderItems(orderItems.filter(item => item.id !== orderItemId));
    } catch (error) {
      console.error('Error deleting order item:', error);
    }
  };

  const updateOrderItem = async (orderItemId, updatedOrderItemData) => {
    try {
      const response = await axios.put(`/api/order-items/${orderItemId}/`, updatedOrderItemData);  // Replace with your API endpoint
      const updatedOrderItems = orderItems.map(item => (item.id === orderItemId ? response.data : item));
      setOrderItems(updatedOrderItems);
    } catch (error) {
      console.error('Error updating order item:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewOrder(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOrderItemChange = (e) => {
    const { name, value } = e.target;
    setNewOrderItem(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div>
      <h2>Orders</h2>
      <div>
        <h3>Add New Order</h3>
        <form onSubmit={(e) => { e.preventDefault(); addOrder(); }}>
          <input type="text" name="customer_name" placeholder="Customer Name" value={newOrder.customer_name} onChange={handleChange} />
          <input type="text" name="customer_contact" placeholder="Customer Contact" value={newOrder.customer_contact} onChange={handleChange} />
          <button type="submit">Add Order</button>
        </form>
      </div>

      <h3>All Orders</h3>
      <ul>
        {orders.map(order => (
          <li key={order.id}>
            <div>
              <p>Customer Name: {order.customer_name}</p>
              <p>Customer Contact: {order.customer_contact}</p>
              <button onClick={() => deleteOrder(order.id)}>Delete Order</button>
              <button onClick={() => updateOrder(order.id, { ...order, customer_name: 'Updated Name' })}>Update Order</button>
              <button onClick={() => fetchOrderItems(order.id)}>Fetch Order Items</button>
            </div>

            <ul>
              {orderItems.map(item => (
                <li key={item.id}>
                  <div>
                    <p>Product ID: {item.product_id}</p>
                    <p>Quantity: {item.quantity}</p>
                    <p>Price: {item.price}</p>
                  </div>
                  <div>
                    <button onClick={() => deleteOrderItem(item.id)}>Delete Item</button>
                    <button onClick={() => updateOrderItem(item.id, { ...item, quantity: item.quantity + 1 })}>Update Quantity</button>
                  </div>
                </li>
              ))}
            </ul>


            <div>
              <h4>Add New Order Item</h4>
              <form onSubmit={(e) => { e.preventDefault(); addOrderItem(); }}>
                <input type="hidden" name="order_id" value={order.id} />
                <input type="text" name="product_id" placeholder="Product ID" value={newOrderItem.product_id} onChange={handleOrderItemChange} />
                <input type="text" name="quantity" placeholder="Quantity" value={newOrderItem.quantity} onChange={handleOrderItemChange} />
                <input type="text" name="price" placeholder="Price" value={newOrderItem.price} onChange={handleOrderItemChange} />
                <button type="submit">Add Order Item</button>
              </form>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrderComponent;

*/