import React, {useState} from 'react'
import DebtComponent from './DebtComponent'
import DebtPayments from './DebtPayments'

const DebtInit = () => {
    const [debt, setDebt] = useState('add debt')
    const [payments, setPayments] = useState(false)

    return (
        <div className='p-4'>
            <button className='btn btn-success me-2 mb-2' onClick={()=>{setDebt('add debt'); setPayments(false)}}>New Debt</button>
            <button className='btn btn-primary me-2 mb-2' onClick={()=>{setDebt('add settlement'); setPayments(false)}}>New Settlement</button>
            {debt === 'add settlement' && <button className='btn btn-warning text-white me-2 mb-2' onClick={()=>setPayments(!payments)}>{payments === false ? 'View Settlements' : 'Debt Payments'}</button>}
            {debt == 'add debt' && <DebtComponent /> }
            {debt == 'add settlement' && <DebtPayments payments={payments} />}
        </div>
    )
}

export default DebtInit