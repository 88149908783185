import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import { useLoadingContext } from './LoadingContext';
import * as XLSX from 'xlsx';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { renderToStaticMarkup } from 'react-dom/server';

const AssignmentComponent = () => {
    const { setLoading } = useLoadingContext()
    const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest()
    const [selectedAssignmentId, setSelectedAssignmentId] = useState(null);
    const inputRef = useRef();
    const [products, setProducts] = useState([]);
    const [assignmentList, setAssignmentList] = useState([]);
    const [users, setUsers] = useState([]);
    const initial = {
        employee: "",
        product: '',
        quantity_assigned: ''
    }
    const [formData, setFormData] = useState(initial);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [assignmentFiltered, setAssignmentFiltered] = useState([])
    const textRef = useRef("Select a user")

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setFormData({
            ...formData,
            employee: ""
        });
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Destructure values from formData
    const { employee, product, quantity_assigned } = formData;

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                // Perform all fetch requests in parallel and wait for all of them to complete
                await Promise.all([fetchProducts(), fetchAssignments(), fetchUsers()]);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                // Set loading to false when all requests are completed (or error occurs)
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const fetchProducts = async () => {
        const response = await getRequest(`${domain}/products`);
        setProducts(response);
    };

    const fetchAssignments = async () => {
        try {
            const response = await getRequest(`${domain}/assignments`);
            setAssignmentList(response);
        } catch (error) {
            console.error('Error fetching assignments:', error);
        }
    };

    const fetchUsers = async () => {
        const response = await getRequest(`${domain}/accounts/users`);
        setUsers(response);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleUpdate = (assignmentItem) => {
        setFormData({
            product: assignmentItem.product,
            quantity_assigned: assignmentItem.quantity_assigned,
            employee: users.find(item => item.id === assignmentItem.employee)?.id
        });
        setSelectedAssignmentId(assignmentItem.id);
        inputRef.current.focus();
    };

    const handleCancelEdit = () => {
        setSelectedAssignmentId(null);
        setFormData(initial);
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            setLoading(true)
            await deleteRequest(`${domain}/assignments/${id}`);
            fetchAssignments();
            setLoading(false)
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formDatas = new FormData();
        for (const key in formData) {
            formDatas.append(key, formData[key]);
        }

        try {
            setLoading(true)
            if (selectedAssignmentId) {
                await putRequest(`${domain}/assignments/${selectedAssignmentId}`, formDatas);
                setSuccessMessage('Assignment updated successfully.');
            } else {
                await postRequest(`${domain}/assignments`, formDatas);
                setSuccessMessage('Assignment added successfully.');
            }
            setLoading(false)
        } catch (error) {
            if (selectedAssignmentId) {
                console.error('Error updating Assignment:', error);
                setErrorMessage('Error updating Assignment.');
            } else {
                console.error('Error adding assignment:', error);
                setErrorMessage('Error adding assignment.');
            }
            setLoading(false)
        }

        setFormData(initial);
        setSelectedAssignmentId(null);
        fetchProducts();
        fetchAssignments();
        event.target.reset()
    };

    function getOwnerName(employeeId) {
        const user = users.find(item => item.id === employeeId);
        return user ? `${user.owner.first_name} ${user.owner.last_name}` : '';
    }

    const exportToExcel = () => {
        //create a date aspect to appent to the download
        let newData = []
        for (let i = 0; i <= assignmentList.length - 1; i++) {
            newData.push({
                productName: `${products && products.find(item => item.id === assignmentList[i].product)?.product_name}`,
                quantity: assignmentList[i].quantity_assigned,
                assignedTo: getOwnerName(assignmentList[i].employee),
                date: new Date(assignmentList[i].date_assigned).toLocaleString()
            })
        }
        const date = new Date();
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        }).replace(/\s+/g, '-'); // Replace spaces with hyphens
        // Create a new workbook
        const wb = XLSX.utils.book_new();
        // Convert JSON data to a worksheet
        const ws = XLSX.utils.json_to_sheet(newData);
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        // Generate a binary string representation of the workbook
        const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });
        // Convert the binary string to a Blob
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        // Create a download link
        const url = URL.createObjectURL(blob);
        // Trigger a click event on the link to download the file
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `assignments-${formattedDate}.xlsx`);
        document.body.appendChild(link);
        link.click();
        // Clean up
        document.body.removeChild(link);
    };

    // Function to convert a string to an ArrayBuffer
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    };

    const PrintDiv = ({ assignment }) => {
        const date = new Date();
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        }).replace(/\s+/g, '-');

        return (
            <div className="print-div">
                <h2>Ashlink Stock Management System</h2>
                <h3>Product Assignment - {getOwnerName(parseInt(formData.employee))}</h3>

                <table>
                    <thead>
                        <tr>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {assignment.map((assignmentItem, index) => (
                            <tr key={index}>
                                <td>{products && products.find(item => item.id === assignmentItem.product)?.product_name}</td>
                                <td>{assignmentItem.quantity_assigned}</td>
                                <td>{new Date(assignmentItem.date_assigned).toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="signature-section">
                    <div className="signature">
                        <p>Issued By:</p>
                        <div className="signature-line"></div>
                    </div>
                    <div className="signature">
                        <p>Received By:</p>
                        <div className="signature-line"></div>
                    </div>
                </div>
                <div style={{textAlign:"center"}}>
                    <small style={{ color:"gray"}}>{formattedDate}</small>
                </div>
            </div>
        );
    };


    const printDiv = () => {
        //const divToPrint = document.getElementById('div-to-print');
        const printWindow = window.open(); // This will create a new window without opening it
        printWindow.document.body.style.visibility = 'hidden';
        printWindow.document.write('<html><head><title>Print</title>');
        printWindow.document.write('<style>');
        // Add your CSS styles here
        printWindow.document.write(`
            /* Add your CSS styles for printing here */
            .print-div {
                width: 100%;
                max-width: 600px;
                margin: 20px auto;
                padding: 20px;
                border: 1px solid #ccc;
                border-radius: 8px;
                font-family: Arial, sans-serif;
            }

            h2, h3 {
                text-align: center;
            }

            table {
                width: 100%;
                border-collapse: collapse;
                margin-bottom: 20px;
            }

            th, td {
                border: 1px solid #ccc;
                padding: 8px;
                text-align: left;
            }

            th {
                background-color: #f2f2f2;
            }

            .signature-section {
                display: flex;
                justify-content: space-between;
                margin-top: 30px;
            }

            .signature {
                width: 48%;
            }

            .signature-line {
                border-top: 1px solid #000;
                height: 20px;
                margin-top: 5px;
            }

        `);
        printWindow.document.write('</style></head><body>');
        // Convert the PrintDiv component to static HTML
        const printDivHtml = renderToStaticMarkup(<PrintDiv assignment={assignmentFiltered} />);
        printWindow.document.write(printDivHtml);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    };

    const handleEmployeeFilter = () => {
        const filtered = assignmentList.filter(assignment => parseInt(assignment.employee) === parseInt(formData.employee))
        setAssignmentFiltered(filtered)
    }

    return (
        <div>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
            <form onSubmit={handleSubmit}>
                <div className='d-flex gap-2 mb-2'>
                    <div className='w-25'>
                        <label>Select Product:</label>
                        <select value={product} name='product' className='w-100' onChange={handleInputChange}>
                            <option value="">Select a product</option>
                            {products.length > 0 && products.map(product => (
                                <option key={product.id} value={product.id}>{product.product_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className='w-25'>
                        <label>Select User:</label>
                        <select value={employee} name='employee' className='w-100' onChange={handleInputChange}>
                            <option value="">Select a user</option>
                            {users.length > 0 && users.map(user => (
                                <option key={user.id} value={user.id}>{user.owner.first_name} {user.owner.last_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className='flex-grow-1'>
                        <label>Quantity Assigned:</label>
                        <input type="number" ref={inputRef} name="quantity_assigned" placeholder='product quantity' value={quantity_assigned} onChange={handleInputChange} />
                    </div>
                </div>
                <button type="submit" className='btn btn-sm btn-success'>{selectedAssignmentId ? 'Update' : 'Add Assignment'}</button>
                {selectedAssignmentId && (
                    <button type='button' onClick={handleCancelEdit} className='btn btn-sm btn-danger ms-2'>Cancel Update</button>
                )}
            </form>
            <div className='d-flex justify-content-between align-items-center'>
                <h3 className='mt-3'>Existing Assignments</h3>
                <div className='d-flex gap-2'>
                    <button className='btn btn-sm btn-primary' onClick={handleShow}>Print Assignment</button>
                    <button className='btn btn-sm btn-danger' onClick={exportToExcel}>Download Assignment List</button>
                </div>
            </div>
            <table>
                <tr>
                    <th style={{ width: 50 }}>No</th>
                    <th>Product Name</th>
                    <th>Quantity</th>
                    <th>Assigned To</th>
                    <th>Date</th>
                    <th style={{ width: 100 }}>Action</th>
                </tr>
                {assignmentList && assignmentList.length > 0 && assignmentList.map((assignmentItem, index) => (
                    <tr key={assignmentItem.id}>
                        <td>{index + 1}</td>
                        <td>{products && products.find(item => item.id === assignmentItem.product)?.product_name}</td>
                        <td>{assignmentItem.quantity_assigned}</td>
                        <td>{getOwnerName(assignmentItem.employee)}</td>
                        <td>{new Date(assignmentItem.date_assigned).toLocaleString()}</td>
                        <td>
                            <div className='d-flex gap-2'>
                                <button onClick={() => handleUpdate(assignmentItem)} className='btn btn-sm btn-danger' disabled={assignmentItem.completed === true && true}>Edit</button>
                                <button onClick={() => handleDelete(assignmentItem.id)} className='btn btn-sm btn-success' disabled={assignmentItem.completed === true && true}>Delete</button>
                            </div>
                        </td>
                    </tr>
                ))}
            </table>
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton style={{ backgroundColor: "var(--background);" }}>
                    <Modal.Title className='fw-bold text-white'>{textRef.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-items-end justify-content-between'>
                        <div >
                            <label>Select User:</label>
                            <select value={employee} name='employee' className='w-100' onChange={handleInputChange}>
                                <option value="">Select a user</option>
                                {users.length > 0 && users.map(user => (
                                    <option key={user.id} value={user.id}>{user.owner.first_name} {user.owner.last_name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <button onClick={handleEmployeeFilter} className='btn btn-success'>Generate Issue Out File</button>
                        </div>
                    </div>
                    {assignmentFiltered.length > 0 &&
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ width: 50 }}>No</th>
                                        <th>Product Name</th>
                                        <th>Quantity</th>
                                        <th>Assigned To</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {assignmentFiltered.map((assignmentItem, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{products && products.find(item => item.id === assignmentItem.product)?.product_name}</td>
                                            <td>{assignmentItem.quantity_assigned}</td>
                                            <td>{getOwnerName(assignmentItem.employee)}</td>
                                            <td>{new Date(assignmentItem.date_assigned).toLocaleString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }

                </Modal.Body>
                {assignmentFiltered.length > 0 &&
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={printDiv}>Continue ...</Button>
                    </Modal.Footer>
                }
            </Modal>
        </div>
    );
};

export default AssignmentComponent;
