import React, {useEffect, useState} from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import useFetch from './useFetch'
import useApiRequest from './useApiRequest'
import "../css/Dashboard.css"
import Account from './Account'
import { useLoginContext } from './LoginContext'
import { domain } from './Domain'
import { useActivePage } from './ActivePageContext'
import { useNavigate } from 'react-router-dom'
import Manage from './Manage'
import AssignmentComponent from './AssignmentComponent'
import { useLoadingContext } from './LoadingContext'
import Statistics from './Statistics'
import DebtInit from './DebtInit'
import ExpensesComponent from './ExpensesComponent'
import WalkIn from './WalkIn'
import notify from './Nots'

const Clock = () => {
    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        // Clean-up function to clear interval when component unmounts
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures effect runs only on mount and unmount

    const formattedDateTime = currentDateTime.toLocaleString(); // Adjust format as needed

    return formattedDateTime
};

const InitialComponent = ({statistics, products, refresh, setRefresh, refreshStats, setRefreshStats, users, loadingusers, refreshUsers, setRefreshUsers, setLoading}) => {
    const {getRequest} = useApiRequest()
    const [displayUsers, setDisplayUsers] = useState(false)
    const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate = ({currentDate}) => {
        return new Intl.DateTimeFormat('en-US', options).format(currentDate);
    }

    const handleUserUpdate = async(user) => {
        if(user.owner.is_active === false){
            const response = await getRequest(`${domain}/accounts/activation?token=${user.token}`)
            notify(response.message, ["Acount Deactivation Successfull!", "Acount Activation Successfull!"].includes(response.message) ? "success" : "error")
        }else{
            const response = await getRequest(`${domain}/accounts/activation?token=${user.token}&deactivate=${true}`)
            notify(response.message, ["Acount Deactivation Successfull!", "Acount Activation Successfull!"].includes(response.message) ? "success" : "error")
        }
        setRefreshUsers(!refreshUsers)
    }
 
    return(
        <>
            <div className='quickStats'>
                <div onClick={()=> setDisplayUsers(!displayUsers)} style={{cursor: "pointer", borderBottom:displayUsers ? "3.5px solid red" : "none"}}>
                    <i class="fa-solid fa-users"></i>
                    {statistics && <span>{statistics.user_count.toLocaleString()}</span>}
                    <p>User Count</p>
                </div>
                <div>
                    <i class="fa-sharp fa-solid fa-coins"></i>
                    {statistics && <span>{statistics.supplier_count.toLocaleString()}</span>}
                    <p>Supplier Count</p>
                </div>
                <div>
                    <i class="fa-solid fa-users"></i>
                    {statistics && <span>{statistics.category_count.toLocaleString()}</span>}
                    <p>Category Count</p>
                </div>
                <div>
                    <i class="fa-sharp fa-solid fa-coins"></i>
                    {statistics && <span>{statistics.product_count.toLocaleString()}</span>}
                    <p>Product Count</p>
                </div>
            </div>
            {displayUsers ? 
            <div className='payments'>
                <div className='d-flex justify-content-between align-items-center'>
                    <h3>Available System Users</h3>
                    <motion.button
                        initial={{y:10}}
                        animate={{y:0}}
                        whileHover={{scale:1.01}}
                        whileTap={{scale:0.9}}
                        onClick={()=>{setRefreshUsers(!refreshUsers);}}
                        className='btn btn-sm btn-success'>Refresh</motion.button>
                </div>
                <table>
                    <tr>
                        <th style={{width: 50}}>No</th>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Gender</th>
                        <th>Category</th>
                        <th style={{width: 180}}>Approve | De-approve</th>
                    </tr>
                    {users && 
                    <>
                        {users.map((user, index) => {
                            return(
                                <tr key={user.id}>
                                    <td>{index + 1}</td>
                                    <td>{user.owner.first_name} {user.owner.last_name}</td>
                                    <td>{user.contact}</td>
                                    <td>{user.gender}</td>
                                    <td>{user.regCategory}</td>
                                    <td className='p-0 m-0'>
                                        {user.owner.is_active ?
                                        <button className={`btn btn-danger w-100 `} style={{borderRadius: 0}} onClick={()=>handleUserUpdate(user)}>De-Approve</button>
                                        :
                                        <button className={`btn btn-success w-100 `} style={{borderRadius: 0}} onClick={()=>handleUserUpdate(user)}>Approve User</button>
                                        }
                                        
                                    </td>
                                </tr>
                            )
                        })}
                    </>
                    }
                </table>
            </div>
            :
            <div className='payments'>
                <div className='d-flex justify-content-between align-items-center'>
                    <h3>Recently Added Products</h3>
                    <motion.button
                        initial={{y:10}}
                        animate={{y:0}}
                        whileHover={{scale:1.01}}
                        whileTap={{scale:0.9}}
                        onClick={()=>{setRefresh(!refresh); setRefreshStats(!refreshStats); setLoading(true)}}
                        className='btn btn-sm btn-success'>Refresh</motion.button>
                </div>
                <table>
                    <tr>
                        <th>No</th>
                        <th>Name of item</th>
                        <th>Cost Price (Ugx)</th>
                        <th>Selling Price (Ugx)</th>
                        <th>Date</th>
                    </tr>
                    {products && 
                    <>
                        {products.slice(0, 10).map((product, index) => {
                            return(
                                <tr key={product.id}>
                                    <td>{index + 1}</td>
                                    <td>{product.product_name}</td>
                                    <td>{product.cost_price.toLocaleString()}</td>
                                    <td>{product.selling_price.toLocaleString()}</td>
                                    <td>{formattedDate(product.created_at)}</td>
                                </tr>
                            )
                        })}
                    </>
                    }
                </table>
            </div>
            }
        </>
    )
}

const Dashboard = () => {
    const {setLoading} = useLoadingContext()
    const navigate = useNavigate();
    const { postRequest } = useApiRequest()
    const { activePage, setActivePage } = useActivePage();
    const { userInfo, setUserInfo, setIsLoggedIn } = useLoginContext();
    const {data:products, loading, refresh, setRefresh, error} = useFetch(`${domain}/products`);
    const {data:users, loading:loadingusers, refresh:refreshUsers, setRefresh:setRefreshUsers, error:errorusers} = useFetch(`${domain}/accounts/users`);
    const {data: statistics,  loading: loadStatistics, refresh:refreshStats, setRefresh:setRefreshStats, error: errStatistics} = useFetch("http://127.0.0.1:8000/stats");
    
    const initial = {
        id: null, 
        token: null, 
        name: null, 
        date: null,
        image: null,
        role: null,
    }

    const handleLogOut = async() => {
        setLoading(true)
        const response = await postRequest(`${domain}/accounts/logout`, {})
        if(response['message'] === "logout successfull"){
            setUserInfo(initial)
            localStorage.setItem('isLoggedIn', false)
            setLoading(false)
            //setIsLoggedIn(!isLoggedIn)
            navigate("/")
        }
    }
    const [current, setCurrent] = useState("initial");

    const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate = ({currentDate}) => {
        return new Intl.DateTimeFormat('en-US', options).format(currentDate);
    } 

    useEffect(()=>{
        loading ? setLoading(true) : setLoading(false)
        loadStatistics ? setLoading(true) : setLoading(false)
        loadingusers ? setLoading(true) : setLoading(false)
    }, [loading, loadStatistics, loadingusers])



    return (
        <div className='dashboard'>
            <div className='div'>
                <div>
                    <img src={domain+`${userInfo.image}`} />
                    <figcaption>{userInfo.name}</figcaption>
                    {userInfo && userInfo.date && <small>created on {formattedDate(userInfo.date)}</small>}
                    <hr />
                    <Link className={activePage === 'dashboard' ? 'active' : ''} onClick={() => {setCurrent("initial");setActivePage("dashboard")}}>Home</Link>
                    <Link className={activePage === 'statistics' ? 'active' : ''} onClick={() => {setCurrent("statistics");setActivePage("statistics")}}>Statistics</Link>
                    <Link className={activePage === 'assignment' ? 'active' : 'd-none'} onClick={() => {setCurrent("assignment");setActivePage("assignment")}}>Assignments</Link>
                    <Link className={activePage === 'manage' ? 'active' : ''} onClick={() => {setCurrent("manage");setActivePage("manage")}}>Stock Management</Link>
                    <Link className={activePage === 'walkIn' ? 'active' : ''} onClick={() => {setCurrent("walkIn");setActivePage("walkIn")}}>Walk Ins</Link>
                    <Link className={activePage === 'expenses' ? 'active' : ''} onClick={() => {setCurrent("expenses");setActivePage("expenses")}}>Expenses</Link>
                    <Link className={activePage === 'debtors' ? 'active' : ''} onClick={() => {setCurrent("debtors");setActivePage("debtors")}}>Debtors</Link>
                    <Link className={activePage === 'account' ? 'active' : ''} onClick={() => {setCurrent("account");setActivePage("account")}}>My Account</Link>
                    <Link onClick={handleLogOut}>Logout</Link>
                </div>
            </div>
            <div className='div'>
                <div className='system_name sticky d-flex justify-content-between'>
                    <p className='ps-4'>Ashlink Stock Management System</p>
                    <p className='pe-4 fw-normal fs-6'><i class="fa-regular fa-clock"></i> <Clock /></p>
                </div>
                <div className='scrollable'>
                    {current === 'initial' && <InitialComponent statistics={statistics} products={products} refresh = {refresh} setRefresh={setRefresh} refreshStats={refreshStats} setRefreshStats={setRefreshStats} setLoading = {setLoading} users={users} loadingusers={loadingusers} refreshUsers={refreshUsers} setRefreshUsers={setRefreshUsers} />}
                    {current === 'statistics' && <Statistics />}
                    {current === 'account' && <Account />}
                    {current === 'manage' && <Manage />}
                    {current === 'assignment' && 
                    <div className='p-4 d-none'>
                        <AssignmentComponent />
                    </div>
                    }
                    {current === 'expenses' && <ExpensesComponent />}
                    {current === 'debtors' && <DebtInit />}
                    {current === 'walkIn' && <WalkIn />}
                </div>
            </div>
        </div>
    )
}

export default Dashboard