import React, {useState, useEffect, useRef} from 'react'
import "../css/Account.css"
import { motion, AnimatePresence } from 'framer-motion'
import { domain } from './Domain'
import useFetch from './useFetch'
import useApiRequest from './useApiRequest';
import Skeleton from 'react-loading-skeleton'
import { useLoginContext } from './LoginContext'
import { useLoadingContext } from './LoadingContext'

const Disclaimer = () => {
    const containerStyle = {
        maxWidth: '100%',
        margin: '20px auto',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    };

    const headingStyle = {
        textAlign: 'center',
        color: '#333',
        marginBottom: '10px',
        fontSize: '15px'
    };

    const paragraphStyle = {
        marginBottom: '10px',
        color: '#666',
    };

    return (
        <div style={containerStyle}>
            <h1 style={headingStyle}>Disclaimer</h1>
            <p style={paragraphStyle}>It is important to keep all your personal information as confidential as possible. you can routinely change your password for additional security & avoid commonly used passwords.</p>
        </div>
    );
}

const Account = () => {
    const {setLoading } = useLoadingContext()
    const { isLoggedIn, setIsLoggedIn, userInfo, setUserInfo } = useLoginContext();
    const imgRef = useRef()
    const [isVisible, setIsVisible] = useState(false)
    const [loaded, setLoaded] = useState(false);
    const { putRequest } = useApiRequest();
    const {data, loading, error, refresh, setRefresh} = useFetch(`${domain}/accounts/profile`);
    const [formData, setFormData] = useState(null)

    const handleImageLoad = () => {
        setLoaded(true);
    };

    const handleChange = (event) => {
        const {name, value} = event.target
        // Update the data state based on the input name
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }

    const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate = ({currentDate}) => {
        return new Intl.DateTimeFormat('en-US', options).format(currentDate);
    } 

    // PUT request
    const handlePut = async (e) => {
        e.preventDefault()
        const formDatas = new FormData(e.target)
        setLoading(true)
        const res = await putRequest(`${domain}/accounts/profile`, formDatas);
        setRefresh(!refresh)
        console.log("we are here")
        if(res['message'] === 'user update successfull'){
            setUserInfo({ ...userInfo, image: res.data.profile,  name: res.data.name})
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
        }
        setLoading(false)
    };

    useEffect(()=>{
        loading ? setLoading(true) : setLoading(false)
    },[loading])

    useEffect(()=>{
        setFormData(data)
    },[data])

    return (
        <motion.div className='d-flex'>
            <AnimatePresence>
                <motion.div
                    className='bg-white AccountForm mb-3 mt-3 m-2'>
                        <p className='d-flex justify-content-between p-2 mb-4 align-items-center border-bottom pb-2'><span className='fw-bold'>Edit Account Information</span> </p>
                        <form onSubmit={handlePut}>
                            <div className='d-flex gap-2'>
                                <div>
                                    <label>Firstname</label>
                                    <input type='text' name='first_name' value={formData && formData.first_name} onChange={handleChange} placeholder='Firstname' />
                                </div>
                                <div>
                                    <label>Lastname</label>
                                    <input type='text' name='last_name' value={formData && formData.last_name} onChange={handleChange} placeholder='Lastname' />
                                </div>
                            </div>
                            <label>Email</label>
                            <input type='email' name='email' value={formData && formData.email} onChange={handleChange} placeholder='Email' />
                            <label>Phone Number</label>
                            <input type='tell' name='contact' value={formData && formData.contact} onChange={handleChange} placeholder='Phone number' />
                            {data && data.regCategory !== 'TrainingCenter' && 
                                <>
                                    <label>Date of birth</label>
                                    <input type='date' name='dob' value={formData && formData.dob} onChange={handleChange}   />
                                </>
                            }
                            <label className='d-none'>Profile Photo</label>
                            <input type='file' ref={imgRef} className='d-none'  name='profile'/>
                            <motion.button
                                whileHover={{scale: 1.05}}
                                whileTap={{scale: 0.9}}
                                className='btn btn-success mt-2'
                                >Update Information</motion.button>
                        </form>
                </motion.div> 
            </AnimatePresence>
            <motion.div className='bg-white w-100 mb-3 mt-3 m-2'>
                <p className='d-flex justify-content-between p-2 mb-4 align-items-center border-bottom pb-2'><span className='fw-bold'>User Saved Profile Information</span> <span className='d-flex gap-2'><i class="fa-solid icon fa-arrows-rotate text-success" onClick={()=>setRefresh(!refresh)} style={{cursor:"pointer"}}></i></span></p>
                <div className='gap-4 details pt-0 ps-3 pe-3 pb-2'>
                    <div className='imgDiv d-flex align-items-center'>
                        <div className='w-25'>
                            {!loaded && <Skeleton height={300} />}
                            <motion.img 
                                title='click me to change profile picture'
                                whileHover={{scale:1.01, x : 10}}
                                whileTap={{scale:0.9, x : 0}}
                                onClick={()=>setIsVisible(!isVisible)}
                                src={data ? domain+data.profile : "/images/user.png" } 
                                onLoad={handleImageLoad}
                                style={{ display: loaded ? 'block' : 'none' }} />
                        </div>
                        <AnimatePresence>
                            {isVisible && (
                                <motion.div
                                    initial={{ scale: 0 }}
                                    animate={{ scale: 1 }}
                                    exit={{ scale: 0 }}
                                    transition={{ type: 'spring', stiffness: 50, damping: 10 }}
                                    className='text-center w-50'>
                                        <motion.button
                                            initial={{x:1000}}
                                            animate={{x:0}}
                                            whileHover={{scale:1.01}}
                                            whileTap={{scale:0.9}}
                                            onClick={()=>imgRef.current.click()}
                                            className='btn btn-sm btn-success'>Change profile picture</motion.button>
                                </motion.div>
                            )}
                        </AnimatePresence>
                        
                    </div>
                    <div className='flex-grow-1 datas align-items-center d-flex flex-wrap'>
                        <div className='w-25'>
                            <p>Name</p>
                            <p>Contact</p>
                            {data && data.regCategory !== 'TrainingCenter' && <p>Gender</p>}
                            <p>Email</p>
                            <p>Joining Date</p>
                            
                        </div>
                        <div className='w-75'>
                            <p>{data ? data.regCategory !== 'TrainingCenter' ? data.first_name + " " + data.last_name: data.name: <Skeleton />}</p>
                            <p>{data ? data.contact : <Skeleton />}</p>
                            {data && <p>{data ? data.gender : <Skeleton />}</p>}
                            <p>{data ? data.email : <Skeleton />}</p>
                            <p>{data ? formattedDate(data.dateJoined) : <Skeleton />}</p>
                        </div>
                    </div>
                    <Disclaimer />
                </div>
            </motion.div>
        </motion.div>
    )
}

export default Account