import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import { useLoadingContext } from './LoadingContext';

const EmployeeAssignments = () => {
    const {setLoading} = useLoadingContext()
    const { postRequest, getRequest, putRequest } = useApiRequest()
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [assignments, setAssignments] = useState([]);
    const [quantitySold, setQuantitySold] = useState({});
    const [damages, setDamages] = useState({});
    const [expiries, setExpiries] = useState({});
    const [declaredAmount, setDeclaredAmount] = useState({});
    const [description, setDescription] = useState({});

    // Fetch employees on component mount
    useEffect(()=>{
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch categories
                await fetchUsers();
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                // Set loading to false when the request is completed (or error occurs)
                setLoading(false);
            }
        };
    
        fetchData();

        return () => setSelectedEmployee(null);
    },[])
    const fetchUsers = async () => {
        const response = await getRequest(`${domain}/accounts/users`);
        setEmployees(response);
    };

    // Fetch assignments when an employee is selected
    useEffect(() => {
        const fetchAssignments = async () => {
            if (selectedEmployee) {
                setLoading(true);
                try {
                    const data = await getRequest(`${domain}/employee-assignments/${selectedEmployee}`);
                    setAssignments(data);
                } catch (err) {
                    console.log(err)
                    //setError(err.message);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchAssignments()
    }, [selectedEmployee]);

    // Handle changes in damages and expiries
    const handleInputChange = (assignmentId, field, value) => {
        if (field === 'quantity_sold') {
            setQuantitySold({ ...quantitySold, [assignmentId]: value });
        }
        else if (field === 'damages') {
            setDamages({ ...damages, [assignmentId]: value });
        } else if (field === 'expiries') {
            setExpiries({ ...expiries, [assignmentId]: value });
        } else if (field === 'declaredAmount') {
            setDeclaredAmount({ ...declaredAmount, [assignmentId]: value });
        }
        else if (field === 'description') {
            setDescription({ ...description, [assignmentId]: value });
        }
    };

    // Calculate expected amount
    /*
    const calculateExpectedAmount = (assignment) => {
        return assignment.quantity_sold * assignment.product.selling_price;
    };
    */

    const calculateExpectedAmount = (assignment) => {
        return assignment.product.selling_price * (quantitySold[assignment.id] || assignment.quantity_sold || 0);
    };

    // Calculate deficit
    const calculateDeficit = (assignmentId, expectedAmount) => {
        const declared = declaredAmount[assignmentId] || 0;
        return expectedAmount - declared;
    };

    // Save the data
    const handleSave = async() => {
        /*
        const data = assignments.map(assignment => ({
            assignmentId: assignment.id,
            quantity_sold: quantitySold[assignment.id] || 0,
            damages: damages[assignment.id] || 0,
            expiries: expiries[assignment.id] || 0,
            declaredAmount: declaredAmount[assignment.id] || 0,
            deficit: calculateDeficit(assignment.id, calculateExpectedAmount(assignment))
        }));
        */
        const formData = new FormData();

        // Convert assignments to JSON and append to FormData
        const assignmentsData = assignments.map(assignment => ({
            assignmentId: assignment.id,
            quantity_sold: parseInt(quantitySold[assignment.id]) || 0,
            damages: parseInt(damages[assignment.id]) || 0,
            description: description[assignment.id] || "",
            expiries: parseInt(expiries[assignment.id]) || 0,
            declaredAmount: parseInt(declaredAmount[assignment.id]) || 0,
            deficit: calculateDeficit(assignment.id, calculateExpectedAmount(assignment))
        }));
    
        // Append the JSON string to FormData
        formData.append('assignments', JSON.stringify(assignmentsData));

        setLoading(true)
        await postRequest(`${domain}/save-assignments`, formData);
        setSelectedEmployee(null); // Reset the form
        setAssignments([]);
        setLoading(false)
    };



    return (
        <div>
            <div className='w-25'>
                <label>Select User:</label>
                <select value={selectedEmployee} name='employee' className='w-100' onChange={(e) => setSelectedEmployee(e.target.value)}>
                    <option value="" selected={selectedEmployee === null && true} disabled>Select an employee</option>
                    {employees.length > 0 && employees.map(user => (
                        <option key={user.id} value={user.id}>{user.owner.first_name} {user.owner.last_name}</option>
                    ))}
                </select>
            </div>
            {assignments.length > 0 && (
                <table className="table">
                <thead>
                    <tr>
                        <th>Product</th>
                        <th>Assigned Quantity</th>
                        <th style={{width: 120}}>Quantity Sold</th>
                        <th style={{width: 100}}>Damages</th>
                        <th style={{width: 200}}>Description</th>
                        <th style={{width: 100}}>Expiries</th>
                        <th>Expected Amount (Ugx)</th>
                        <th style={{width: 200}}>Declared Amount</th>
                        <th>Deficit (Ugx)</th>
                    </tr>
                </thead>
                <tbody>
                    {assignments.map(assignment => (
                        <tr key={assignment.id}>
                            <td>{assignment.product.product_name}</td>
                            <td>{assignment.quantity_assigned}</td>
                            <td>
                                <input
                                    type="number"
                                    value={quantitySold[assignment.id] || assignment.quantity_sold || ''}
                                    onChange={(e) => handleInputChange(assignment.id, 'quantity_sold', e.target.value)}
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={damages[assignment.id] || ''}
                                    onChange={(e) => handleInputChange(assignment.id, 'damages', e.target.value)}
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <input 
                                    type='text' 
                                    value={description[assignment.id] || ''}
                                    onChange={(e) => handleInputChange(assignment.id, 'description', e.target.value)}
                                    placeholder='Damage description'
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={expiries[assignment.id] || ''}
                                    onChange={(e) => handleInputChange(assignment.id, 'expiries', e.target.value)}
                                    className="form-control"
                                />
                            </td>
                            <td>{calculateExpectedAmount(assignment).toLocaleString()}</td>
                            <td>
                                <input
                                    type="number"
                                    value={declaredAmount[assignment.id] || ''}
                                    onChange={(e) => handleInputChange(assignment.id, 'declaredAmount', e.target.value)}
                                    className="form-control"
                                />
                            </td>
                            <td>{calculateDeficit(assignment.id, calculateExpectedAmount(assignment)).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            )}
            {assignments.length > 0 && (
                <div className='mt-2 d-flex justify-content-end'>
                    <button className='btn btn-danger' onClick={handleSave}>Save</button>
                </div>
            )}
        </div>
    )
}

export default EmployeeAssignments