
import React, { useEffect } from 'react';
import 'animate.css';
import "./App.css"
import useFetch from './components/useFetch';
import { Route, Routes } from "react-router-dom"
import { ActivePageProvider } from './components/ActivePageContext';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import Activation from './components/Activation';
import Register from './components/Register';
import OTPForm from './components/OTPForm';
import PasswordResetForm from './components/PasswordResetForms';
import Dashboard from './components/Dashboard';
import NotFoundPage from './components/NotFoundPage';
import PrivateRoutes from './components/ProtectedRoutes';
import { useLoadingContext } from './components/LoadingContext';
import Loader from './components/Loader';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function App() { 
  const { loading } = useLoadingContext()
  return (
    <div className="App">
      <ToastContainer />
      {loading && <Loader />}
      <ActivePageProvider>
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/otp' element={<OTPForm />} />
            <Route path='/register' element={<Register />} />
            <Route path='/accounts/activation' element={<Activation />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/accounts/password-reset/:slug' element={<PasswordResetForm />} />
            <Route element={<PrivateRoutes />}>
              <Route path='/dashboard' element={<Dashboard />} />
            </Route>
            <Route path="*" element={< NotFoundPage />} />
          </Routes>
      </ActivePageProvider>
    </div>
  );
}

export default App;