import React, { useState, useEffect } from 'react'
import "../css/Statistics.css"
import useFetch from './useFetch'
import { domain } from './Domain'
import { useLoadingContext } from './LoadingContext'
import Chart from "chart.js/auto";
import { CategoryScale, plugins } from "chart.js";
import { Bar } from 'react-chartjs-2';


const Statistics = () => {
    const { setLoading } = useLoadingContext()
    const { data, loading, refresh, setRefresh, error } = useFetch(`${domain}/statistics`);

    useEffect(() => {
        loading ? setLoading(true) : setLoading(false)
    }, [loading])

    // Example data structure (fetch data from API)

    // Arrays to store weeks and amount_paid
    const [weeks, setWeeks] = useState([]);
    const [amountPaid, setAmountPaid] = useState([]);
    const [damages, setDamages] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [expiring, setExpiring] = useState([]);
    const [walkIn, setWalkIn] = useState([]);

    const datas = {
        labels: weeks,
        datasets: [
            {
                label: 'Sales',
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(75,192,192,0.6)',
                hoverBorderColor: 'rgba(75,192,192,1)',
                data: amountPaid,
            },
            {
                label: 'Damages',
                backgroundColor: 'rgba(255, 99, 71,0.4)',
                borderColor: 'rgba(255, 99, 71,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255, 99, 71,0.6)',
                hoverBorderColor: 'rgba(255, 99, 71,1)',
                data: damages,
            },
            {
                label: 'Expenses',
                backgroundColor: 'rgba(100, 200, 71,0.4)',
                borderColor: 'rgba(100, 200, 71,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(100, 200, 71,0.6)',
                hoverBorderColor: 'rgba(100, 200, 71,1)',
                data: expenses,
            },
            {
                label: 'Expirires',
                backgroundColor: 'rgba(75,255,192,0.4)',
                borderColor: 'rgba(75,255,192,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(75,255,192,0.6)',
                hoverBorderColor: 'rgba(75,255,192,1)',
                data: expiring,
            },
            {
                label: 'WalkIn',
                backgroundColor: 'rgba(75,55,192,0.4)',
                borderColor: 'rgba(75,55,192,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(75,55,192,0.6)',
                hoverBorderColor: 'rgba(75,55,192,1)',
                data: walkIn,
            },
        ],
    };

    const options = {
        plugins:{
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: "A bar chart displaying total sales and damages for each week of the current month"
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "weeks of the month"
                }
            },
            y: {
                title: {
                    display: true,
                    text: "Total Amount"
                }
            },
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                },
            }],
            xAxes: [{
                ticks: {
                    autoSkip: false, // Prevent auto-skipping of labels
                },
            }],
        },
        maintainAspectRatio: true,
        responsive: true,
        height: 250
    }

    
    // Iterate through each object in data
    useEffect(()=>{
        const weeksArray = [];
        const amountPaidArray = [];
        const damageArray = [];
        const expenseArray = [];
        const ExpiryArray = []
        const WalkInArray = []
        data && data.plot_data.forEach(item => {
            weeksArray.push(new Date(item.week).toDateString());
            amountPaidArray.push(item.amount_paid);
            damageArray.push(item.total_damages)
            expenseArray.push(item.total_expenses)
            ExpiryArray.push(item.expiring_products_count)
            WalkInArray.push(item.walk_in_sales)
        });
        // Update state variables
        setWeeks(weeksArray);
        setAmountPaid(amountPaidArray);
        setDamages(damageArray)
        setExpenses(expenseArray)
        setExpiring(ExpiryArray)
        setWalkIn(WalkInArray)
    },[data])

    return (
        <div className="statistics-dashboard m-4" >
            <div className='w-50'>
                {data && data.plot_data && <Bar data={datas} options={options} className='w-auto' />}
            </div>
            <h3>Quick Statistics</h3>
            {data && (
                <div className="statistics-container">
                    <div className="statistic-item">
                        <h4>Total Stock Value</h4>
                        <p>{data.total_stock_value && data.total_stock_value.toLocaleString()}</p>
                    </div>
                    <div className="statistic-item">
                        <h4>Total Damages</h4>
                        <p>{data.total_damages && data.total_damages.toLocaleString()}</p>
                    </div>
                    <div className="statistic-item">
                        <h4>Total Assignments</h4>
                        <p>{data.total_assignments && data.total_assignments.toLocaleString()}</p>
                    </div>
                    <div className="statistic-item">
                        <h4>Low Stock Products</h4>
                        <ul>
                            {data.low_stock_products.map((item, index) => (
                                <li key={index}>{Object.keys(item)[0]}: {Object.values(item)[0]}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="statistic-item">
                        <h4>Products per Supplier</h4>
                        <ul>
                            {data.products_per_supplier.map((item, index) => (
                                <li key={index}>{Object.keys(item)[0]}: {Object.values(item)[0]}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="statistic-item">
                        <h4>Average Assignments per Employee</h4>
                        <ul>
                            {data.average_assignments_per_employee.map((item, index) => (
                                <li key={index}>{Object.keys(item)[0]}: {Object.values(item)[0]}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="statistic-item">
                        <h4>Damages by Product</h4>
                        <ul>
                            {data.damages_by_product.map((item, index) => (
                                <li key={index}>{item['assignment__product__product_name']}: {item['num_damages']}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            )
            }
        </div >
    )
}

export default Statistics